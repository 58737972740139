<template>
  <div class="contenu-facture-libre" ref="contenu">
    <loader v-if="loading" />
    <div class="body-box-rapport scroll-bar" id="filiale-box-body">
      <div class="col-sm-12 p-0">
        <form @submit.prevent="submitFactureLibre">
          <div
            v-if="facture"
            class="table-rapport-style table-rapport-style-type"
          >
            <div class="row ml-1">
              <b-form-group
                label="Type *"
                label-for="Type"
                class="input-modal-champ p-1 col-3"
              >
                <b-form-select
                  id="type_facture"
                  v-model="facture.type"
                  :options="computedPermissionType"
                  class="b-form-select-raduis"
                  @change="OnchangeFactureType(facture.type)"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Date de création *"
                label-for="Date de création"
                class="input-modal-champ p-1 col-2"
              >
                <b-form-input
                  type="date"
                  id="dateCreation"
                  v-model="facture.date_creation"
                  :min="minDateForAvoir"
                  :max="maxDate"
                  :disabled="!checkPermission('FLDFAC')"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Lieu de création"
                label-for="Lieu de création"
                class="input-modal-champ p-1 col-3"
              >
                <b-form-input
                  id="lieu_creation"
                  v-model="facture.lieu_creation"
                  autocomplete="off"
                  :disabled="computedCheckTypeFactureAvoir"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label=""
                label-for="categorie"
                class="input-modal-champ p-1 col-4"
              >
                <div class="d-flex">
                  <p class="mb-2">Catégorie</p>
                  <button
                    v-if="facture && computedCheckTypeFactureNotAvoir"
                    type="button"
                    v-b-modal.addCategorieModal
                    class="ml-2 btn-add-tva-unite"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    />
                  </button>
                </div>
                <b-form-select
                  id="categorie"
                  v-model="facture.categorie_id"
                  :options="categorieList"
                  class="b-form-select-raduis"
                  text-field="nom_categorie"
                  value-field="id"
                  :disabled="computedCheckTypeFactureAvoir"
                ></b-form-select>
              </b-form-group>
            </div>
            <div
              v-if="facture && facture.type === 'Facture d\'avoir'"
              class="row ml-1"
            >
              <b-form-group
                label="N° du document corrigé *"
                label-for="N° du document corrigé "
                class="input-modal-champ col-5"
                v-if="computedCheckTypeFactureAvoir"
              >
                <b-form-input
                  v-click-outside="onClickOutsideNumDoc"
                  @input="filterResultsNumDoc(facture.numero_document_corrige)"
                  id="numero_document_corrige"
                  autocomplete="off"
                  v-model="facture.numero_document_corrige"
                  required
                ></b-form-input>
                <div>
                  <ul
                    v-if="resultsListAvoir && isOpenDocumentList"
                    class="
                      autocomplete-results
                      list-unstyled list_satyle_num_avoir
                    "
                  >
                    <li
                      v-for="result in resultsListAvoir"
                      :key="result.id"
                      @click="setResultsListAvoir(result.num)"
                    >
                      {{ result.num }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
              <b-form-group
                label="Type de l'avoir:"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  id="radio-group"
                  v-model="facture.motif_avoir"
                  :options="optionsAvoir"
                  :aria-describedby="ariaDescribedby"
                  name="radio-option"
                  @change="onChangeTypeAvoir"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
          <hr />
          <div class=" table-rapport-style table-rapport-style-societe">
            <div v-if="facture && facture.vendeur" class="m-1">
              <br />
              <div class="row">
                <b-form-group class="mr-3 mb-0 col-12">
                  <select-simple-with-search
                    :value="facture.vendeur.id"
                    :options="
                      getFiliaeOfResponsable.map(i => {
                        return {
                          ...i,
                          full_name:
                            i.abreviation == null
                              ? i.name + `(pas d'abréviation)`
                              : i.name,
                          $isDisabled: i.abreviation == null ? true : false
                        };
                      }).filter(item => item.type != 'organisme')
                    "
                    placeholder="sélectionner une Sociéte"
                    label="full_name"
                    trackBy="id"
                    @changeValue="
                      facture.vendeur.id = $event;
                      vendeurSelected();
                    "
                    :disabled="computedCheckTypeFactureAvoir"
                    :refreshValue="true"
                    :loading="loadingSociete"
                  />
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Nom *"
                  label-for="Nom vendeur"
                  class="input-modal-champ col-6"
                >
                  <b-form-input
                    id="nom_compagnie_vendeur"
                    v-model="facture.vendeur.nom_compagnie"
                    required
                    autocomplete="off"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Type Société"
                  label-for="Nom vendeur"
                  class="nput-modal-champ col-6 pl-0"
                >
                  <b-form-select
                    id="type_societe_vendeur"
                    v-model="facture.vendeur.type_societe"
                    autocomplete="off"
                    :options="getTypeSociete"
                    value-field="type"
                    text-field="type"
                    class="b-form-select-raduis"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="row mb-0">
                <b-form-group class="input-modal-champ col-3 pr-0 mb-0">
                  <b-form-select
                    id="num_tva_siren_vendeur"
                    v-model="facture.vendeur.siren_tva"
                    :options="num_tva_siren"
                    required
                    class="b-form-select-raduis"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="input-modal-champ col-9 mb-0"
                  v-if="
                    facture &&
                      facture.vendeur &&
                      facture.vendeur.siren_tva === 'Numéro TVA'
                  "
                >
                  <b-form-input
                    v-if="facture && facture.vendeur"
                    id="siren_value_vendeur"
                    v-model="facture.vendeur.tva_value"
                    required
                    autocomplete="off"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="input-modal-champ col-9 mb-0"
                  v-if="
                    facture &&
                      facture.vendeur &&
                      facture.vendeur.siren_tva === 'SIREN'
                  "
                >
                  <b-form-input
                    v-if="facture && facture.vendeur"
                    id="siren_tva_value "
                    v-model="facture.vendeur.siren_value"
                    required
                    autocomplete="off"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>
              </div>
              <b-form-group
                label="N° et nom de rue *"
                label-for="N° et nom de rue"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  id="adress_vendeur"
                  v-model="facture.vendeur.adresse"
                  autocomplete="off"
                  required
                  :disabled="computedCheckTypeFactureAvoir"
                ></b-form-textarea>
              </b-form-group>
              <div class="row m-0">
                <b-form-group
                  label="Code Postal *"
                  label-for="Code Postal"
                  class="input-modal-champ col-3 mb-0 pl-0"
                >
                  <b-form-input
                    id="code_postal_vendeur"
                    v-model="facture.vendeur.code_postal"
                    autocomplete="off"
                    required
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Ville *"
                  label-for="Ville"
                  class="input-modal-champ col-9 mb-0 pr-0"
                >
                  <b-form-input
                    id="ville_vendeur"
                    v-model="facture.vendeur.ville"
                    autocomplete="off"
                    required
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>
              </div>

              <button
                v-if="!hideVendeur"
                class="more_less"
                @click="showMoreVendeur"
                type="button"
              >
                plus <font-awesome-icon icon="angle-down" class="mt-1" />
              </button>

              <div v-if="hideVendeur">
                <b-form-group
                  label="Pays *"
                  label-for="Pays"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    id="pays"
                    v-model="facture.vendeur.pays"
                    class="b-form-select-raduis"
                    :options="uniqueArrayOfCountries"
                    text-field="nom"
                    value-field="nom"
                    required
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Siret *"
                  label-for="IBAN"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    id="siret"
                    required
                    v-model="facture.vendeur.siret"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>

                <div class="row">
                  <b-form-group
                    label="Adress Mail"
                    label-for="Adress Mail"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      required
                      id="email"
                      type="email"
                      v-model="facture.vendeur.email"
                      :disabled="computedCheckTypeFactureAvoir"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Site intenet"
                    label-for="Site intenet"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="site_internet"
                      v-model="facture.vendeur.site_internet"
                      :disabled="computedCheckTypeFactureAvoir"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group
                    label="Fax"
                    label-for="Fax"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="fax"
                      v-model="facture.vendeur.fax"
                      :disabled="computedCheckTypeFactureAvoir"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Téléphone *"
                    label-for="Téléphone"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="tel"
                      required
                      v-model="facture.vendeur.telephone"
                      :disabled="computedCheckTypeFactureAvoir"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  label="Description additionnelle"
                  label-for="description_additionnelle"
                  class="input-modal-champ pr-1 pl-1 m-0"
                >
                  <b-form-textarea
                    id="description"
                    rows="2"
                    v-model="facture.vendeur.description"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <button
                v-if="hideVendeur"
                class="more_less"
                @click="showMoreVendeur"
                type="button"
              >
                moins <font-awesome-icon icon="angle-up" class="m-0" />
              </button>
            </div>
          </div>
          <div
            v-if="facture && facture.acheteur"
            class="table-rapport-style table-rapport-style-client"
          >
            <div>
              <h5>Client</h5>
              <b-form-group
                class="mb-3 ml-5 "
                label-cols-sm="1"
                v-slot="{ ariaDescribedby }"
                v-if="
                  facture &&
                    facture.acheteur &&
                    facture.acheteur.type &&
                    checkPermission('FLRP')
                "
              >
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="facture.acheteur.type"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options-1"
                  @change="resetAcheteur"
                  :disabled="computedCheckTypeFactureAvoir"
                >
                  <button
                    v-if="
                      facture &&
                        facture.acheteur &&
                        facture.acheteur.type == 'passage' &&
                        checkPermission('FLRP')
                    "
                    type="button"
                    v-b-modal.addClientModal
                    class="ml-2 btn-add-tva-unite"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    /></button
                ></b-form-radio-group>
              </b-form-group>
              <div
                class="row mt-2"
                v-if="facture.acheteur.type === 'professionnel'"
              >
                <b-form-group
                  label="Nom de société *"
                  label-for="Nom vendeur"
                  class="nput-modal-champ col-6 mb-0"
                >
                  <select-simple-with-search
                    :value="facture.acheteur.id"
                    :options="professionnelSocite"
                    placeholder="sélectionner une Sociéte"
                    @changeValue="
                      facture.acheteur.id = $event;
                      acheteurSelectedPro();
                    "
                    :disabled="computedCheckTypeFactureAvoir"
                    :refreshValue="true"
                    :loading="loadingClient"
                  />
                </b-form-group>
                <b-form-group
                  label="Type Société"
                  label-for="Nom vendeur"
                  class="nput-modal-champ col-6 mb-0"
                >
                  <b-form-select
                    id="type_societe_acheteur"
                    v-model="facture.acheteur.type_societe"
                    autocomplete="off"
                    :options="getTypeSociete"
                    value-field="type"
                    text-field="type"
                    class="b-form-select-raduis"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="row mt-2" v-if="facture.acheteur.type === 'passage'">
                <b-form-group
                  label="Nom de client *"
                  label-for="Nom vendeur"
                  class="mr-3 mb-0 col-12"
                >
                  <select-simple-with-search
                    :value="facture.acheteur.id"
                    :options="passageClient"
                    placeholder="sélectionner un client"
                    @changeValue="
                      facture.acheteur.id = $event;
                      acheteurSelectedPro();
                    "
                    :disabled="computedCheckTypeFactureAvoir"
                    :refreshValue="true"
                    :loading="loadingClient"
                  />
                </b-form-group>
              </div>
              <div
                class="row mt-2"
                v-if="facture.acheteur.type === 'particulier'"
              >
                <b-form-group
                  label="Civilite"
                  label-for="civilite"
                  class="input-modal-champ col-4 pr-0 m-0"
                >
                  <b-form-select
                    id="genre"
                    v-model="facture.acheteur.civilite"
                    :options="genreList"
                    required
                    class="b-form-select-raduis"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Nom et prénom *"
                  label-for="Nom acheteur"
                  class="nput-modal-champ col-8 mb-0"
                >
                  <b-form-select
                    id="nom_société"
                    v-model="facture.acheteur.id"
                    autocomplete="off"
                    text-field="name_part"
                    value-field="id"
                    required
                    @change="acheteurSelectedParticulier"
                    :options="particulierSocite"
                    class="b-form-select-raduis"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="row mb-0 mt-3">
                <b-form-group class="input-modal-champ col-3 pr-0 mb-0">
                  <b-form-select
                    id="num_tva_siren_acheteur"
                    v-model="facture.acheteur.siren_tva"
                    :options="num_tva_siren"
                    class="b-form-select-raduis"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="input-modal-champ col-9 mb-0"
                  v-if="
                    facture &&
                      facture.acheteur &&
                      facture.acheteur.siren_tva === 'Numéro TVA'
                  "
                >
                  <b-form-input
                    v-if="facture && facture.acheteur"
                    id="siren_value_acheteur "
                    v-model="facture.acheteur.tva_value"
                    autocomplete="off"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="input-modal-champ col-9 mb-0"
                  v-if="
                    facture &&
                      facture.acheteur &&
                      facture.acheteur.siren_tva === 'SIREN'
                  "
                >
                  <b-form-input
                    v-if="facture && facture.acheteur"
                    id="siren_value_ach"
                    v-model="facture.acheteur.siren_value"
                    autocomplete="off"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>
              </div>
              <b-form-group
                label="N° et nom de rue *"
                label-for="N° et nom de rue"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  id="adress_acheteur"
                  v-model="facture.acheteur.adresse"
                  autocomplete="off"
                  :required="
                    facture.acheteur.type === 'particulier' ? false : true
                  "
                  :disabled="computedCheckTypeFactureAvoir"
                ></b-form-textarea>
              </b-form-group>
              <div class="row m-0">
                <b-form-group
                  label="Code Postal *"
                  label-for="Code Postal"
                  class="input-modal-champ col-3 mb-0 pl-0"
                >
                  <b-form-input
                    id="code_postal_acheteur"
                    v-model="facture.acheteur.code_postal"
                    :required="
                      facture.acheteur.type === 'particulier' ? false : true
                    "
                    autocomplete="off"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Ville *"
                  label-for="Ville"
                  class="input-modal-champ col-9 mb-0 pr-0"
                >
                  <b-form-input
                    id="ville_acheteur"
                    v-model="facture.acheteur.ville"
                    :required="
                      facture.acheteur.type === 'particulier' ? false : true
                    "
                    autocomplete="off"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>
              </div>
              <button
                v-if="!hideAcheteur"
                class="more_less"
                @click="showMoreAcheteur"
                type="button"
              >
                plus <font-awesome-icon icon="angle-down" class="mt-1" />
              </button>
              <div v-if="hideAcheteur">
                <b-form-group
                  label="Pays"
                  label-for="Pays"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    id="pays"
                    v-model="facture.acheteur.pays"
                    :options="uniqueArrayOfCountries"
                    class="b-form-select-raduis"
                    text-field="nom"
                    value-field="nom"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Description additionnelle"
                  label-for="description_additionnelle"
                  class="input-modal-champ pr-1 pl-1 m-0"
                >
                  <b-form-textarea
                    id="description"
                    rows="2"
                    v-model="facture.acheteur.description"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-textarea>
                </b-form-group>
                <!-- <div class="row">
                  <b-form-group
                    label="Banque"
                    label-for="Banque"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="banque"
                      v-model="facture.acheteur.banque"
                      :disabled="computedCheckTypeFactureAvoir"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="BIC"
                    label-for="Swift"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="swift"
                      v-model="facture.acheteur.swift"
                      :disabled="computedCheckTypeFactureAvoir"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  label="IBAN"
                  label-for="Nom de famille"
                  class="input-modal-champ pl-1 m-0"
                >
                  <b-form-input
                    id="iban_acheteur"
                    v-model="facture.acheteur.iban"
                    autocomplete="off"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group> -->
                <b-form-group
                  label="Siret"
                  label-for="iban"
                  class="input-modal-champ pl-1 m-0"
                >
                  <b-form-input
                    id="siret_acheteur"
                    v-model="facture.acheteur.siret"
                    autocomplete="off"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Adresse(s) Email"
                  label-for="Adress_email"
                  class="input-modal-champ m-0"
                >
                  <b-form-input
                    type="email"
                    id="email"
                    v-model="facture.acheteur.email"
                    autocomplete="off"
                    :disabled="computedCheckTypeFactureAvoir"
                  ></b-form-input>
                </b-form-group>
                <div class="row m-0">
                  <b-form-group
                    label="Téléphone"
                    label-for="tel"
                    class="input-modal-champ col-6 p-0 m-0"
                  >
                    <b-form-input
                      id="tel_portable"
                      v-model="facture.acheteur.telephone"
                      autocomplete="off"
                      :disabled="computedCheckTypeFactureAvoir"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Téléphone portable"
                    label-for="tel_portable"
                    class="input-modal-champ p-0 col-6 m-0"
                  >
                    <b-form-input
                      id="tel_portable"
                      v-model="facture.acheteur.telephone_portable"
                      autocomplete="off"
                      :disabled="computedCheckTypeFactureAvoir"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <button
                  v-if="hideAcheteur"
                  class="more_less"
                  @click="showMoreAcheteur"
                  type="button"
                >
                  moins <font-awesome-icon icon="angle-up" class="m-0" />
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="facture"
            class="mb-1 mt-2  table-rapport-style background-style table-rapport-style-type"
          >
            <b-form-group
              label=""
              label-for="Objet"
              rows="1"
              class="pr-0 pb-2 pl-1 m-0 mt-2"
            >
              <div class="d-flex">
                <p>Objet</p>
              </div>
              <EditorSimple
                classComponent="objet"
                :disabledComponent="computedCheckTypeFactureAvoir"
                v-model="facture.objet"
                @changeText="changeTextObjet"
              ></EditorSimple>
            </b-form-group>
          </div>
          <hr />
          <div class="card mb-5">
            <div class="card-header p-1 text-dark">
              Désignation
              <button
                type="button"
                v-if="showDep"
                class="btn btn-outline-secondary mr-2 p-1 float-right"
                @click="hideDepot"
              >
                Cacher Dépot
              </button>
              <button
                type="button"
                v-if="!showDep"
                class="btn btn-outline-secondary mr-2 p-1 float-right"
                @click="showDepot"
              >
                Ajouter Dépot
              </button>
              <button
                type="button"
                v-if="showReduc"
                class="btn btn-outline-secondary mr-2 p-1 float-right"
                @click="hideReduction"
              >
                Cacher Réduction
              </button>
              <button
                type="button"
                v-if="!showReduc"
                class="btn btn-outline-secondary mr-2 p-1 float-right"
                @click="showReduction"
              >
                Ajouter Réduction
              </button>
            </div>
            <div v-if="facture && facture.produits" class="card-body w-100">
              <div
                :class="
                  computedCheckTypeFactureAvoir
                    ? 'row text-dark margin-hesder-table'
                    : 'row text-dark '
                "
              >
                <p
                  class="col-lg-1 mb-1"
                  v-if="facture && computedCheckTypeFactureAvoir"
                ></p>
                <p
                  :class="
                    facture && computedCheckTypeFactureAvoir
                      ? 'input-modal-champ col-lg-1 ml-3 p-0 mb-1'
                      : 'input-modal-champ col-lg-2 ml-3 p-0 mb-1'
                  "
                >
                  Nom *
                </p>
                <p class="col-lg-1 pt-pr-pb-0 mb-1 font-tabel-produit">
                  Réf. *
                </p>
                <p class="col-lg-1 mb-1 font-tabel-produit" v-if="showDep">
                  Dépôt *
                </p>
                <p class="col-lg-1 mb-1 font-tabel-produit">Qté *</p>
                <p class="col-lg-1 mb-1 font-tabel-produit">
                  Unité *
                  <button
                    v-if="facture && computedCheckTypeFactureNotAvoir"
                    class="ml-2 btn-add-tva-unite"
                    type="button"
                    v-b-modal.addUnitModal
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    />
                  </button>
                </p>
                <p class="col-lg-1 mb-1 font-tabel-produit">PU HT *</p>
                <p
                  class="col-lg-1 mb-1 font-tabel-produit"
                  v-if="
                    showReduc && facture.comment_calculer_facture === 'montant'
                  "
                >
                  Red. Mt *
                </p>
                <p
                  class="col-lg-1 mb-1 font-tabel-produit"
                  v-if="
                    showReduc &&
                      facture.comment_calculer_facture === 'pourcentage'
                  "
                >
                  Red. % *
                </p>
                <p class="col-lg-1 mb-1 font-tabel-produit">Net HT *</p>
                <p class="col-lg-1 mb-1 mr-2 font-tabel-produit">
                  TVA % *<button
                    v-if="facture && computedCheckTypeFactureNotAvoir"
                    type="button"
                    v-b-modal.addtvaModal
                    class="ml-2 btn-add-tva-unite"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    />
                  </button>
                </p>
                <p class="col-lg-1 mb-1 font-tabel-produit">Total TTC *</p>
              </div>
              <draggable :list="facture.produits">
                <div
                  v-for="(produit, index) in facture.produits"
                  :key="index"
                  class="ml-2 text-dark"
                >
                  <p class="m-0" hidden>{{ (produit.index = index + 1) }}</p>
                  <template v-if="produit.type === 'produit'">
                    <div class="row">
                      <div
                        v-if="facture && computedCheckTypeFactureAvoir"
                        class="ml-1"
                      >
                        <b>Avant </b>
                        <br />
                        <p>correction</p>
                      </div>
                      <b-form-group
                        :class="
                          facture && computedCheckTypeFactureAvoir
                            ? 'input-modal-champ col-lg-1 ml-3 p-0 mb-1'
                            : 'input-modal-champ col-lg-2 ml-3 p-0 mb-1'
                        "
                      >
                        <b-form-input
                          :disabled="facture && computedCheckTypeFactureAvoir"
                          :id="'product' + index"
                          v-model="produit.nom"
                          v-click-outside="onClickOutsideProduct"
                          @input="
                            filterResultsProduit(produit.nom, index, 'avant')
                          "
                          autocomplete="off"
                          required
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="produit.nom"
                        ></b-form-input>
                        <div>
                          <ul
                            :key="'produitUl' + index"
                            v-if="resultsProduits && produit.isOpenProduitList"
                            class="
                              autocomplete-results
                              list-unstyled list_style_products
                            "
                          >
                            <li
                              v-for="result in resultsProduits"
                              :key="result"
                              @click="setResultsProduct(result, produit)"
                            >
                              {{ result }}
                            </li>
                          </ul>
                        </div>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 p-0 mb-1">
                        <b-form-input
                          :disabled="facture && computedCheckTypeFactureAvoir"
                          id="reference"
                          v-model="produit.reference"
                          required
                          autocomplete="off"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="showDep"
                        class="input-modal-champ col-lg-1 p-0 mb-1"
                      >
                        <b-form-input
                          id="depot"
                          v-model="produit.depot"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group class="input-modal-champ col-lg-1 mb-1 p-0">
                        <b-form-input
                          :disabled="facture && computedCheckTypeFactureAvoir"
                          @input="calculTotalHtTtc(produit)"
                          class="padding-input-number"
                          id="qte"
                          v-model="produit.qte"
                          type="number"
                          step="any"
                          required
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Qte"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 p-0">
                        <b-form-select
                          :disabled="facture && computedCheckTypeFactureAvoir"
                          id="unite"
                          v-model="produit.unite"
                          :options="getAllUnites"
                          class="b-form-select-raduis"
                          text-field="valeur"
                          value-field="valeur"
                          data-toggle="tooltip"
                          data-placement="top"
                          required
                          title="Unité"
                          @change="changeUnite(produit)"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 mb-1 p-0">
                        <b-form-input
                          :disabled="facture && computedCheckTypeFactureAvoir"
                          @input="calculTotalHtTtc(produit)"
                          class="padding-input-number"
                          id="pu_ht"
                          v-model="produit.pu_ht"
                          required
                          type="number"
                          step="any"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pu HT"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="showReduc"
                        class="input-modal-champ col-lg-1 p-0 mb-1"
                      >
                        <b-form-input
                          :disabled="facture && computedCheckTypeFactureAvoir"
                          id="reduction"
                          v-model="produit.reduction"
                          type="number"
                          :max="
                            facture.comment_calculer_facture === 'pourcentage'
                              ? 100
                              : produit.pu_ht
                          "
                          :min="0"
                          step="any"
                          @input="change_reduction(produit)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Réduction"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 p-0">
                        <b-form-input
                          @input="onChangeTotalHt(produit)"
                          id="total_ht"
                          v-model="produit.total_ht"
                          class="padding-input-number"
                          type="number"
                          step="any"
                          disabled
                          required
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Net HT"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 p-0">
                        <b-form-select
                          @change="calculTotalHtTtc(produit)"
                          id="tva"
                          v-model="produit.tva"
                          required
                          :options="getAllTvas"
                          class="b-form-select-raduis"
                          text-field="valeur"
                          value-field="valeur"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="TVA %"
                          :disabled="facture && computedCheckTypeFactureAvoir"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 p-0">
                        <b-form-input
                          id="total_ttc"
                          v-model="produit.total_ttc"
                          @input="changeTotalTtc(produit)"
                          class="padding-input-number"
                          type="number"
                          step="any"
                          disabled
                          required
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Total TTC"
                        ></b-form-input>
                      </b-form-group>
                      <b-button
                        v-if="facture && computedCheckTypeFactureNotAvoir"
                        class="button-danger-style ml-2 mr-2 mb-3 mt-0"
                        size="sm"
                        variant="danger"
                        @click="deleteProduct(index)"
                      >
                        X
                      </b-button>
                      <b-form-checkbox
                        v-if="facture && computedCheckTypeFactureNotAvoir"
                        class="col-5 ml-4"
                        :id="'produit' + index"
                        v-model="produit.isSelectInput"
                        :value="true"
                        :unchecked-value="false"
                      >
                        Ajouter le produit à votre catalogue
                      </b-form-checkbox>
                    </div>
                    <div class="row">
                      <template
                        v-if="
                          produit.type === 'produit' &&
                            facture &&
                            computedCheckTypeFactureAvoir
                        "
                      >
                        <div class="ml-1">
                          <b>Aprés</b>
                          <br />
                          <p>correction</p>
                        </div>

                        <b-form-group
                          class="input-modal-champ col-lg-1 p-0 ml-3 mb-1"
                        >
                          <b-form-input
                            :disabled="facture && computedCheckTypeFactureAvoir"
                            :id="'product' + index"
                            v-model="produit.nom_apres"
                            v-click-outside="onClickOutsideProduct"
                            @input="
                              filterResultsProduit(
                                produit.nom_apres,
                                index,
                                'apres'
                              )
                            "
                            autocomplete="off"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="produit.nom_apres"
                          ></b-form-input>
                          <div>
                            <ul
                              :key="'produitUl' + index"
                              v-if="
                                resultsProduits &&
                                  produit.isOpenProduitList_apres
                              "
                              class="
                                autocomplete-results
                                list-unstyled list_style_products
                              "
                            >
                              <li
                                v-for="result in resultsProduits"
                                :key="result"
                                @click="setResultsProduct(result, produit)"
                              >
                                {{ result }}
                              </li>
                            </ul>
                          </div>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-lg-1 p-0 mb-1"
                        >
                          <b-form-input
                            :disabled="facture && computedCheckTypeFactureAvoir"
                            id="reference"
                            v-model="produit.reference_apres"
                            required
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="showDep"
                          class="input-modal-champ col-lg-1 p-0 mb-1"
                        >
                          <b-form-input
                            id="depot"
                            v-model="produit.depot_apres"
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          class="input-modal-champ col-lg-1 mb-1 p-0"
                        >
                          <b-form-input
                            @input="calculTotalHtTtc_apres(produit)"
                            id="qte"
                            v-model="produit.qte_apres"
                            class="padding-input-number"
                            required
                            type="number"
                            step="any"
                            :max="produit.qte_apres_avoir"
                            :disabled="
                              facture.motif_avoir === 'avoir_financier'
                            "
                          ></b-form-input>
                          <div
                            v-if="facture.motif_avoir === 'retour_avoir'"
                            class="error-message d-flex justify-content-center"
                          >
                            <div class="error">
                              Rest qte à avoir
                              {{
                                produit && produit.qte_apres_avoir
                                  ? produit.qte_apres_avoir
                                  : '0.00'
                              }}
                            </div>
                          </div>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-select
                            id="unite"
                            v-model="produit.unite_apres"
                            :options="getAllUnites"
                            class="b-form-select-raduis"
                            text-field="valeur"
                            value-field="valeur"
                            required
                            disabled
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          class="input-modal-champ col-lg-1 mb-1 p-0"
                        >
                          <b-form-input
                            @input="calculTotalHtTtc_apres(produit)"
                            id="pu_ht"
                            v-model="produit.pu_ht_apres"
                            required
                            type="number"
                            class="padding-input-number"
                            step="any"
                            autocomplete="off"
                            :max="
                              facture.comment_calculer_facture === 'pourcentage'
                                ? produit.net_ht_apres_avoir /
                                  (produit.qte_apres *
                                    (1 - produit.reduction_apres / 100))
                                : produit.max_pu_ht
                            "
                            :min="
                              facture.comment_calculer_facture === 'pourcentage'
                                ? 0
                                : produit.reduction_apres
                            "
                            :disabled="facture.motif_avoir === 'retour_avoir'"
                          ></b-form-input>
                          <div
                            v-if="facture.motif_avoir === 'avoir_financier'"
                            class="error-message d-flex justify-content-center"
                          >
                            <div class="error">
                              Prix unitaire net actuel
                              {{ produit.pu_ht_apres_avoir }}
                            </div>
                          </div>
                        </b-form-group>
                        <b-form-group
                          v-if="showReduc"
                          class="input-modal-champ col-lg-1 p-0 mb-1"
                        >
                          <b-form-input
                            @input="change_reduction_apres(produit)"
                            id="reduction_apres"
                            v-model="produit.reduction_apres"
                            class="padding-input-number"
                            type="number"
                            step="any"
                            autocomplete="off"
                            :max="
                              facture.comment_calculer_facture === 'pourcentage'
                                ? 100
                                : produit.pu_ht_apres
                            "
                            :disabled="
                              facture.motif_avoir === 'retour_avoir' ||
                                facture.motif_avoir === 'avoir_financier'
                            "
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-input
                            @input="calculTotalHtTtc_apres(produit)"
                            id="total_ht_apres"
                            v-model="produit.total_ht_apres"
                            autocomplete="off"
                            readonly
                            type="number"
                          ></b-form-input>
                          <div
                            v-if="facture.motif_avoir === 'ristourne'"
                            class="error-message d-flex justify-content-center"
                          >
                            <div class="error">
                              Net Ht à avoir
                              {{
                                (
                                  Math.round(produit.net_ht_apres_avoir * 100) /
                                  100
                                ).toFixed(2)
                              }}
                            </div>
                          </div>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-select
                            @change="calculTotalHtTtc_apres(produit)"
                            id="tva"
                            v-model="produit.tva_apres"
                            disabled
                            required
                            :options="getAllTvas"
                            class="b-form-select-raduis"
                            text-field="valeur"
                            value-field="valeur"
                          ></b-form-select>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-input
                            id="total_ttc_apres"
                            v-model="produit.total_ttc_apres"
                            @input="changeTotalTtcApres(produit)"
                            class="padding-input-number"
                            required
                            type="number"
                            disabled
                            step="any"
                          ></b-form-input>
                        </b-form-group>
                      </template>
                    </div>
                    <hr v-if="facture && computedCheckTypeFactureAvoir" />
                    <b-row class="row" v-else>
                      <EditorSimple
                        classComponent="produit-description"
                        v-model="produit.description"
                        @changeText="changeText($event, index)"
                      ></EditorSimple>
                    </b-row>
                  </template>

                  <template
                    v-if="
                      produit.type === 'sous_total' &&
                        facture &&
                        computedCheckTypeFactureNotAvoir
                    "
                    ><div class="row">
                      <b-form-group class="input-modal-champ col-11 ml-2 p-0">
                        <b-form-input
                          disabled
                          :value="
                            'Sous total Net Ht : ' +
                              computedSumNetHtBeforSt(index)
                          "
                        ></b-form-input>
                      </b-form-group>
                      <b-button
                        class="button-danger-style mr-2 mb-3 mt-0"
                        size="sm"
                        variant="danger"
                        @click="deleteProduct(index)"
                      >
                        X
                      </b-button>
                    </div>
                  </template>
                  <template
                    v-if="
                      produit.type === 'saut_page' &&
                        facture &&
                        computedCheckTypeFactureNotAvoir
                    "
                  >
                    <div class="row">
                      <b-form-group class="input-modal-champ col-11 ml-2 p-0">
                        <b-form-input
                          v-model="sautPage"
                          disabled
                        ></b-form-input>
                      </b-form-group>
                      <b-button
                        class="button-danger-style mr-2 mb-3 mt-0"
                        size="sm"
                        variant="danger"
                        @click="deleteProduct(index)"
                      >
                        X
                      </b-button>
                    </div>
                  </template>
                  <template
                    v-if="
                      produit.type === 'ligne_text' &&
                        facture &&
                        computedCheckTypeFactureNotAvoir
                    "
                  >
                    <div class="row align-items-center">
                      <b-form-group class="input-modal-champ col-11 ml-2 p-0">
                        <EditorSimple
                          classComponent="ligne-text"
                          v-model="produit.contenu"
                          @changeText="changeLigneText($event, index)"
                        ></EditorSimple>
                      </b-form-group>
                      <b-button
                        class="button-danger-style-ligne-text"
                        size="sm"
                        variant="danger"
                        @click="
                          deleteProduct(produit.index, produit.type, produit)
                        "
                      >
                        X
                      </b-button>
                    </div>
                  </template>
                </div>
              </draggable>
            </div>

            <div class="row ml-2">
              <b-button
                v-if="facture && computedCheckTypeFactureNotAvoir"
                variant="success"
                class="config-btn ml-2 col-1 p-0 mb-1"
                @click="addNewProduct"
              >
                <font-awesome-icon icon="plus" /> Produit
              </b-button>
              <b-button
                v-if="facture && computedCheckTypeFactureNotAvoir"
                variant="light"
                class="config-btn ml-2 col-2 mb-1 button-produit"
                @click="addNewTextLine"
              >
                <font-awesome-icon icon="plus" /> Ligne de texte
              </b-button>
              <b-button
                v-if="facture && computedCheckTypeFactureNotAvoir"
                variant="light"
                class="config-btn ml-2 col-2 mb-1 p-0 button-produit"
                @click="addNewSautPage"
              >
                <font-awesome-icon icon="plus" /> Saut de page
              </b-button>
              <b-button
                v-if="facture && computedCheckTypeFactureNotAvoir"
                variant="light"
                class="config-btn ml-2 col-1 mb-1 p-0 button-produit"
                @click="addNewSousToltal"
              >
                <font-awesome-icon icon="plus" /> Sous-total
              </b-button>
            </div>
          </div>
          <div class="row mb-4 mr-3">
            <div class="col-lg-8"></div>
            <div v-if="facture && facture.devise" class="col-lg-4">
              <table class="table">
                <tbody>
                  <tr class="m-2">
                    <td><b>Total HT</b></td>
                    <td class="text-right pr-3">
                      {{
                        formateValueFacture(
                          (Math.round(totalHt * 100) / 100).toFixed(2)
                        ) || 0
                      }}
                    </td>
                    <td>
                      <b-form-group class="input-modal-champ m-0 p-0">
                        <b-form-select
                          id="devise_table"
                          v-model="facture.devise"
                          :options="currencyList"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </td>
                  </tr>
                  <template>
                    <td class="td-width"><b>Total remise </b></td>
                    <td class="text-right pr-3">
                      {{
                        formateValueFacture(
                          (Math.round(totalReduction * 100) / 100).toFixed(2)
                        ) || 0
                      }}
                    </td>
                    <td>{{ facture.devise }}</td></template
                  >
                  <tr>
                    <td><b>Net HT</b></td>
                    <td class="text-right pr-3">
                      {{
                        formateValueFacture(
                          (Math.round(NetHt * 100) / 100).toFixed(2)
                        ) || 0
                      }}
                    </td>
                    <td>{{ facture.devise }}</td>
                  </tr>
                  <tr>
                    <td><b>Total TVA</b></td>
                    <td class="text-right pr-3">
                      {{ formateValueFacture(total_tva.toFixed(2)) || 0 }}
                    </td>
                    <td class="ml-3">{{ facture.devise }}</td>
                  </tr>
                  <tr>
                    <td><b>Total TTC</b></td>
                    <td class="text-right pr-3">
                      {{
                        formateValueFacture(
                          (Math.round(totalTtc * 100) / 100).toFixed(2)
                        ) || 0
                      }}
                    </td>
                    <td>{{ facture.devise }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="facture" class="background-style p-3 m-2">
            <div class="row" v-if="facture && computedCheckReglement">
              <b-form-group
                label="Mode de règlement *"
                label-for="Mode de règlement"
                class="input-modal-champ m-0 col-3"
              >
                <b-form-select
                  id="mode_reglement"
                  v-model="facture.mode_reglement"
                  :options="computedTypeReglement"
                  class="b-form-select-raduis"
                  :disabled="computedCheckTypeFactureAvoir"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Conditions de paiement *"
                label-for="Condition de paiement"
                class="input-modal-champ m-0 col-3 p-0"
              >
                <b-form-select
                  id="date_limite_reglement"
                  v-model="facture.date_limite_reglement"
                  :options="computedConditionPaiement"
                  :disabled="computedCheckTypeFactureAvoir"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="facture.date_limite_reglement === 'Date choisie'"
                class="input-modal-champ m-0 col-2 mt-4 p-0"
              >
                <b-form-input
                  type="date"
                  id="date_exact_limite_reglement"
                  class="input-modal-champ"
                  v-model="facture.date_exact_limite_reglement"
                  :disabled="computedCheckTypeFactureAvoir"
                />
              </b-form-group>
              <b-form-group
                label="Etat *"
                label-for="Etat"
                class="input-modal-champ m-0 col-2"
              >
                <b-form-select
                  id="etat"
                  v-model="facture.etat"
                  required
                  :options="
                    facture.type === 'Facture proforma'
                      ? etatListProforma
                      : etatList
                  "
                  class="b-form-select-raduis"
                  :disabled="true"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="facture.type !== 'Facture proforma'"
                label="Acompte Payé *"
                label-for="Acompte Payé"
                class="input-modal-champ col-2"
              >
                <b-form-input
                  id="montant_payer"
                  v-model="facture.montant_payer"
                  required
                  disabled
                  :max="totalTtc"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              v-if="showReduc && facture && computedCheckReglement"
              class="row"
            >
              <b-form-group
                label="Comment calculer la réduction *"
                label-for="Comment calculer la réduction"
                class="input-modal-champ col-3"
              >
                <b-form-select
                  id="comment_calculer_facture"
                  v-model="facture.comment_calculer_facture"
                  required
                  :options="calculeReduction"
                  class="b-form-select-raduis"
                  @change="onChangeCommentCalculer"
                  :disabled="computedCheckTypeFactureAvoir"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="facture.comment_calculer_facture !== 'montant'"
                label="Ajouter une réduction globale (%)"
                label-for="Ajouter une réduction globale ( %)"
                class="input-modal-champ col-3"
              >
                <b-form-input
                  id="ajout_reduction_global"
                  v-model="facture.ajout_reduction_global"
                  class="padding-input-number"
                  type="number"
                  max="100"
                  @input="
                    onChangeGlobalReduction(facture.ajout_reduction_global)
                  "
                  :disabled="computedCheckTypeFactureAvoir"
                ></b-form-input>
              </b-form-group>
            </div>
            <b-col
              col
              xs="12"
              sm="8"
              md="8"
              v-if="facture && computedCheckReglement"
            >
              <!-- Rib  -->
              <b-form-group label="RIB *" label-for="rib-template-select">
                <multiselect
                  v-model="rib"
                  :options="getRibOfFilials"
                  :multiple="false"
                  label="rib"
                  track-by="id"
                  required
                  class="multiselect-vue-custom-style-cdg"
                  :showLabels="false"
                  @select="onChangeRibSelect($event)"
                  @remove="toggleUnSelectMarket($event)"
                >
                </multiselect>
              </b-form-group>
            </b-col>
            <div class="row">
              <template v-if="facture && computedCheckReglement">
                <b-form-group
                  label="IBAN *"
                  label-for="iban"
                  class="input-modal-champ col-3"
                >
                  <b-form-input
                    id="iban"
                    v-model="facture.iban"
                    required
                    autocomplete="off"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="BIC *"
                  label-for="swift"
                  class="input-modal-champ col-3"
                >
                  <b-form-input
                    id="swift"
                    v-model="facture.swift"
                    required
                    autocomplete="off"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="BANQUE *"
                  label-for="bank"
                  class="input-modal-champ col-3"
                >
                  <b-form-input
                    id="bank"
                    v-model="facture.bank"
                    required
                    autocomplete="off"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <!-- <b-form-group
                label="Banque"
                label-for="Banque"

                class="input-modal-champ col-2 "
                v-if="facture && facture.vendeur && facture.vendeur.banque"
              >
                <b-form-input
                  id="banque"
                  v-model="facture.vendeur.banque"
                  :disabled="computedCheckTypeFactureAvoir"
                ></b-form-input>
              </b-form-group> -->
                <b-form-group
                  label="Devise *"
                  label-for="devise"
                  class="input-modal-champ col-3"
                >
                  <b-form-select
                    id="devise_facture"
                    v-model="facture.devise"
                    :options="currencyList"
                    class="b-form-select-raduis"
                  ></b-form-select> </b-form-group
              ></template>
              <b-form-group class="input-modal-champ col-12 mb-0">
                <b-row class="d-flex">
                  <b-col class="col-1">
                    <p>Description</p>
                  </b-col>
                  <b-col class="col-6">
                    <b-form-group
                      class="mb-1 displayDiscriptionClass ml-5"
                      label-cols-sm="1"
                      v-slot="{ ariaDescribedby1 }"
                    >
                      <b-form-radio-group
                        id="radio-group-2"
                        class="d-flex"
                        v-model="facture.displayDiscription"
                        :options="displayFactureOptions"
                        :aria-describedby="ariaDescribedby1"
                        name="radio-options-2"
                        :disabled="computedCheckTypeFactureAvoir"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <EditorSimple
                  classComponent="description-facture"
                  v-model="facture.description"
                  @changeText="changeTextDescription"
                  :disabledComponent="computedCheckTypeFactureAvoir"
                ></EditorSimple>
              </b-form-group>
            </div>
          </div>
          <div class="error-message d-flex justify-content-center">
            <div class="error">
              <ul
                v-if="Array.isArray(errorAddFactureLibre)"
                class="mb-0 list-group"
              >
                <li v-for="(e, index) in errorAddFactureLibre" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ errorAddFactureLibre }}</div>
              <div v-if="errorValidation">{{ this.errorValidation }}</div>
            </div>
          </div>
          <hr />
          <div
            v-if="loderTemplatesList === true"
            class="message text-aligne-center"
          >
            <div v-if="loderTemplatesList" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
          <div
            v-if="templateSociete.length != 0"
            class="background-style p-3 m-2"
          >
            <b-col
              col
              xs="12"
              sm="3"
              md="3"
              v-if="facture && templateSociete.length != 0"
            >
              <b-form-group
                label="Template de téléchargement"
                label-for="description"
                class="input-modal-champ col-12 mb-0"
              >
                <b-form-select
                  id="template_id"
                  v-model="facture.template_id"
                  class="b-form-select-raduis mr-3 p-1 mb-0 col-12"
                  :disabled="computedCheckTypeFactureAvoir"
                >
                  <b-form-select-option
                    v-for="option in templateSociete"
                    :key="option.id"
                    :value="option.id"
                    >{{ option.name }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
          </div>
          <div class="background-style p-3 m-2">
            <div class="body-box-setting">
              <div class="doc-list-file mt-1 box-upload">
                <div class="form-type-file">
                  <div class="form-groupe">
                    <div>
                      <b-form-file
                        v-model="files"
                        ref="file-type"
                        :required="false"
                        placeholder="Aucun fichier selectionné"
                        drop-placeholder="Drop file here..."
                        multiple
                      >
                      </b-form-file>
                    </div>
                    <div id="upload-file-component" class="mt-3">
                      <div
                        class="files-to-upload"
                        v-for="(file, index) in files"
                        :key="'file' + index"
                      >
                        <p class="file-name">
                          <font-awesome-icon
                            icon="paperclip"
                            class="file-upload-icon ml-3"
                          />
                          {{ file.name }}
                        </p>
                        <p class="file-name">
                          <b-form-group
                            label="Description"
                            label-for="description"
                            class="input-modal-champ"
                          ></b-form-group>
                          <b-form-textarea
                            id="description"
                            v-model="file.description"
                          ></b-form-textarea>
                        </p>
                        <p class="file-name-trash">
                          <font-awesome-icon
                            icon="trash"
                            class="file-trash-upload-icon"
                            @click="deleteUploadFile(index)"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-button
            class="button-13 mb-1 mr-2"
            type="submit"
            :disabled="disabledSaveButton"
            >Sauvegarde</b-button
          >
          <b-button
            class="button-13 mb-1 w-25"
            v-b-modal.displayModal
            @click="displayBill"
          >
            Aperçu provisoire</b-button
          >

          ou
          <b-link href="/facture-libre"> Retour </b-link>
          <b-modal
            ref="displayModal"
            id="displayModal"
            no-close-on-backdrop
            :hide-footer="true"
            :hide-header="true"
            title="Facture brouillant"
            modal-class="cutsom-modal-bootstrap modal-dialog-visualisation"
          >
            <div class="hader mb-2">
              <div class="titleSetting">Afficher Facture</div>
              <div class="iconClose" @click.prevent="hideModal('displayModal')">
                <font-awesome-icon icon="times" />
              </div>
            </div>
            <Card>
              <template v-slot:body>
                <iframe
                  height="750"
                  width="700"
                  :src="pdfSource + '#toolbar=0'"
                  scrolling="no"
                ></iframe>
              </template>
            </Card>
          </b-modal>
          <b-modal
            ref="addUnitModal"
            id="addUnitModal"
            no-close-on-backdrop
            :hide-footer="true"
            @hidden="resteTvaUniteModal"
            title="Ajouter Unite"
            modal-class="cutsom-modal-bootstrap"
          >
            <b-form-group
              label="Unité"
              label-for="unite"
              class="input-modal-champ"
            >
              <b-form-input
                id="unite"
                v-model="valeur"
                required
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <div class="error-message d-flex justify-content-center">
              <div class="error">
                <div>{{ errorTvaUnite }}</div>
              </div>
            </div>
            <div class="form-modal-custom-style mt-2">
              <div class="actionModel">
                <b-button
                  class="button-valide-style"
                  @click="addUnite('unité')"
                >
                  <span>
                    Valider
                    <div v-if="loadingTvaUnite" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </div>
          </b-modal>
          <b-modal
            ref="addtvaModal"
            id="addtvaModal"
            no-close-on-backdrop
            :hide-footer="true"
            @hidden="resteTvaUniteModal"
            title="Ajouter tva"
            modal-class="cutsom-modal-bootstrap"
          >
            <b-form-group
              label="Tva"
              label-for="unite"
              class="input-modal-champ"
            >
              <b-form-input
                id="tva"
                v-model="valeur"
                required
                type="number"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <div class="error-message d-flex justify-content-center">
              <div class="error">
                <div>{{ errorTvaUnite }}</div>
              </div>
            </div>
            <div class="form-modal-custom-style mt-2">
              <div class="actionModel">
                <b-button class="button-valide-style" @click="addTav('tva')">
                  <span>
                    Valider
                    <div v-if="loadingTvaUnite" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </div>
          </b-modal>
          <b-modal
            ref="addCategorieModal"
            id="addCategorieModal"
            no-close-on-backdrop
            :hide-footer="true"
            @hidden="resteCategorieModal"
            title="Ajouter catégorie"
            modal-class="cutsom-modal-bootstrap"
          >
            <b-form-group
              label="Catégorie"
              label-for="cate"
              class="input-modal-champ"
            >
              <b-form-input
                id="cat"
                v-model="nom_categorie"
                required
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <div class="error-message d-flex justify-content-center">
              <div class="error">
                <div>{{ errorCategorie }}</div>
              </div>
            </div>
            <div class="form-modal-custom-style mt-2">
              <div class="actionModel">
                <b-button class="button-valide-style" @click="addCat()">
                  <span>
                    Valider
                    <div v-if="loadingCategorie" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </div>
          </b-modal>
          <!-- ADD MODAL CLIENT  -->
          <b-modal
            ref="addClientModal"
            id="addClientModal"
            :hide-footer="true"
            :hide-header="true"
            @hidden="resetModal()"
            no-close-on-backdrop
            modal-class="cutsom-modal-bootstrap modal-dialog-visualisation-add-client-passage"
          >
            <div class="hader mb-2">
              <div class="titleSetting">Ajouter un client passage</div>
              <div
                class="iconClose"
                @click.prevent="hideModal('addClientModal')"
                title="Fermer"
              >
                <font-awesome-icon icon="times" />
              </div>
            </div>
            <Card>
              <template v-slot:body>
                <form
                  @submit.prevent.stop="handleSubmitClient"
                  class="form-modal-custom-style"
                >
                  <b-form-group
                    label="Nom de client *"
                    label-for="Nom vendeur"
                    class="input-modal-champ col-3 mb-0 pl-0"
                  >
                    <b-form-input
                      id="siren_value_ach"
                      v-model="configurationClientToAdd.name"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <div class="row mb-0 mt-3">
                    <b-form-group class="input-modal-champ col-3 pr-0 mb-0">
                      <b-form-select
                        id="num_tva_siren_acheteur"
                        v-model="configurationClientToAdd.siren_tva"
                        :options="num_tva_siren"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="input-modal-champ col-9 mb-0"
                      v-if="
                        configurationClientToAdd &&
                          configurationClientToAdd.siren_tva === 'Numéro TVA'
                      "
                    >
                      <b-form-input
                        v-if="configurationClientToAdd"
                        id="siren_value_acheteur "
                        v-model="configurationClientToAdd.tva_value"
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="input-modal-champ col-9 mb-0"
                      v-if="
                        configurationClientToAdd &&
                          configurationClientToAdd.siren_tva === 'SIREN'
                      "
                    >
                      <b-form-input
                        id="siren_value_ach"
                        v-model="configurationClientToAdd.siren_value"
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                    {{ configurationClientToAdd.siren_tva }}
                  </div>
                  <b-form-group
                    label="N° et nom de rue *"
                    label-for="N° et nom de rue"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-textarea
                      id="adress_acheteur"
                      v-model="configurationClientToAdd.adresse"
                      autocomplete="off"
                      required
                    ></b-form-textarea>
                  </b-form-group>
                  <div class="row m-0">
                    <b-form-group
                      label="Code Postal *"
                      label-for="Code Postal"
                      class="input-modal-champ col-4 mb-0 pl-0"
                    >
                      <b-form-input
                        id="code_postal_acheteur"
                        v-model="configurationClientToAdd.code_postal"
                        autocomplete="off"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Pays *"
                      label-for="Pays"
                      class="input-modal-champ p-0 col-4 m-0"
                    >
                      <b-form-select
                        id="pays"
                        v-model="configurationClientToAdd.pays"
                        :options="uniqueArrayOfCountries"
                        class="b-form-select-raduis"
                        text-field="nom"
                        value-field="nom"
                        required
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Ville *"
                      label-for="Ville"
                      class="input-modal-champ p-0 col-4 m-0"
                    >
                      <b-form-input
                        id="ville_acheteur"
                        v-model="configurationClientToAdd.ville"
                        autocomplete="off"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <b-form-group
                    label="Description additionnelle"
                    label-for="description_additionnelle"
                    class="input-modal-champ pr-1 pl-1 m-0"
                  >
                    <b-form-textarea
                      id="description"
                      rows="2"
                      v-model="configurationClientToAdd.description"
                    ></b-form-textarea>
                  </b-form-group>
                  <div class="row m-0">
                    <b-form-group
                      label="Siret"
                      label-for="iban"
                      class="input-modal-champ col-6 p-0 m-0"
                    >
                      <b-form-input
                        id="siret_acheteur"
                        v-model="configurationClientToAdd.siret"
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="Adresse Email *"
                      label-for="Adress_email"
                      class="input-modal-champ col-6 p-0 m-0"
                    >
                      <b-form-input
                        type="email"
                        id="email"
                        v-model="configurationClientToAdd.email"
                        autocomplete="off"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="row m-0">
                    <b-form-group
                      label="Téléphone *"
                      label-for="tel"
                      class="input-modal-champ col-6 p-0 m-0"
                    >
                      <b-form-input
                        id="tel_portable"
                        v-model="configurationClientToAdd.telephone"
                        autocomplete="off"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Téléphone portable"
                      label-for="tel_portable"
                      class="input-modal-champ p-0 col-6 m-0"
                    >
                      <b-form-input
                        id="tel_portable"
                        v-model="configurationClientToAdd.telephone_portable"
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="actionModel mt-2">
                    <div class="messageError">
                      <div v-if="error" class="error">
                        <ul v-if="Array.isArray(error)">
                          <li v-for="(e, index) in error" :key="index">
                            {{ e }}
                          </li>
                        </ul>
                        <div v-else>{{ error }}</div>
                      </div>
                    </div>
                    <b-button type="submit" class="button-valide-style mt-3">
                      <span>
                        Valider
                        <div v-if="loading" class="loading ml-2">
                          <div class="spinner-border" role="status"></div>
                        </div>
                      </span>
                    </b-button>
                  </div>
                </form>
              </template>
            </Card>
          </b-modal>
          <!-- END ADD MODAL CLIENT -->
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
import loader from '../Loader.vue';
import ClickOutside from 'vue-click-outside';
import SelectSimpleWithSearch from '../component/selectSimpleWithSearch.vue';
export default {
  data() {
    return {
      loadingSociete: false,
      loadingClient: false,
      minDateForAvoir: null,
      res: null,
      checked: false,
      id_societe: null,
      testRetourErrors: false,
      loderTemplatesList: false,
      disabledSaveButton: false,
      loadingClientPassage: false,
      configurationClientToAdd: {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: 'passage'
      },
      options: [
        { text: 'Professionnel', value: 'professionnel' },
        { text: 'Particulier', value: 'particulier' },
        { text: 'Passage', value: 'passage' }
      ],
      optionsAvoir: [
        { text: 'Retour avoir', value: 'retour_avoir' },
        { text: 'Avoir financier', value: 'avoir_financier' },
        { text: 'Ristourne', value: 'ristourne' }
      ],
      displayFactureOptions: [
        { text: 'Afficher discription', value: true },
        { text: 'Cacher discription', value: false }
      ],
      typeFacture: ['Facture', "Facture d'avoir", 'Facture proforma'],
      loadingCategorie: null,
      errorCategorie: null,
      errorTvaUnite: null,
      results: [],
      errorValidation: null,
      idAvoir: null,
      resultsProduits: [],
      resultsListAvoir: [],
      uniteList: [],
      categorieList: [],
      pdfSource: null,
      sousTotal: 'Sous totale',
      sautPage: 'Saut de page',
      disabledNumFactureAvoir: false,
      loading: false,
      showReduc: false,
      showDep: false,
      hideVendeur: false,
      hideAcheteur: false,
      nom_categorie: null,
      valeur: null,
      rib: null,
      loadingTvaUnite: false,
      genreList: ['M.', 'Mme', 'Mmes', 'Mrs', 'M & Mme'],
      num_tva_siren: ['Numéro TVA', 'SIREN'],
      listTva: [],
      currencyList: ['EUR', 'USD'],
      etatList: ['Payé', 'Payé en partie', 'A payer'],
      etatListProforma: ['Créé'],
      calculeReduction: ['pourcentage', 'montant'],
      type: null,
      error: null,
      maxDate: moment(Date.now()).format('YYYY-MM-DD'),
      isOpenAcheteurList: false,
      isOpenDocumentList: false,
      professionnelSocite: [],
      particulierSocite: [],
      passageClient: [],
      filialsListNames: [],
      filialsListParticuluerNames: [],
      productListName: [],
      avoirListNums: [],
      files: [],
      uniqueArrayOfCountries: [],
      facture: {
        template_id: null,
        numero_document_corrige: null,
        objet_color: '#c3c7e2',
        text_color: '#000000',
        motif_avoir: 'retour_avoir',
        devise: 'EUR',
        num: null,
        date_creation: moment(Date.now()).format('YYYY-MM-DD'),
        lieu_creation: null,
        categorie_id: null,
        objet: null,
        type: 'Facture',
        mode_reglement: null,
        date_limite_reglement: null,
        date_exact_limite_reglement: null,
        etat: 'A payer',
        description: null,
        montant_payer: 0,
        comment_calculer_facture: 'pourcentage',
        ajout_reduction_global: 0,
        swift: null,
        iban: null,
        objet: null,
        check: false,
        date_paiement: null,
        famille: 'libre',
        vendeur: {
          nom_compagnie: null,
          code_postal: null,
          siren_tva: 'Numéro TVA',
          siren_value: null,
          tva_value: null,
          adresse: null,
          code_postal: null,
          ville: null,
          siret: null,
          type_societe: null,
          pays: null,
          iban: null,
          banque: null,
          swift: null,
          email: null,
          site_internet: null,
          fax: null,
          telephone: null,
          id_vendeur: null,
          description: null,
          filiale_id: null
        },
        acheteur: {
          type: 'professionnel',
          civilite: 'M.',
          prenom: null,
          type_societe: null,
          nom_famille: null,
          nom_compagnie: null,
          siren_tva: 'Numéro TVA',
          siren_value: null,
          tva_value: null,
          adresse: null,
          code_postal: null,
          ville: null,
          banque: null,
          swift: null,
          pays: null,
          email: null,
          siret: null,
          iban: null,
          telephone: null,
          telephone_portable: null,
          description: null,
          filiale_id: null
        },
        produits: [
          {
            contenu: '',
            nom: null,
            reference: null,
            depot: null,
            reduction: 0,
            qte: 1,
            unite: null,
            tva: '20',
            pu_ht: 0,
            total_ttc: 0,
            total_ht: 0,
            description: null,
            isSelectInputTva: false,
            isSelectInput: true,
            index: 0,
            type: 'produit',
            isOpenProduitList: false,
            contenu_apres: '',
            nom_apres: null,
            reference_apres: null,
            depot_apres: null,
            reduction_apres: 0,
            qte_apres: 1,
            unite_apres: ' ',
            tva_apres: 0,
            pu_ht_apres: 0,
            total_ttc_apres: 0,
            total_ht_apres: 0,
            isSelectInputTva_apres: false,
            isSelectInput_apres: true,
            index_apres: 0,
            isOpenProduitList_apres: false,
            qte_sous_total_avoir: 0,
            pu_ht_sous_total_avoir: 0,
            total_ht_sous_total_avoir: 0,
            total_ttc_sous_total_avoir: 0,
            max_pu_ht: 0,
            adresse: null
          }
        ],
        displayDiscription: true
      },
      focusInput: null
    };
  },
  components: {
    draggable,
    loader,
    Card: () => import('@/views/component/card.vue'),
    SelectSimpleWithSearch,
    EditorSimple: () => import('@/views/component/EditorSimple.vue')
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'errorAddFactureLibre',
      'addFactureLibre',
      'templateSociete',
      'getSettingFilialesTh',
      'getPaysList',
      'getProduits',
      'getAllCategories',
      'getListFactureForAvoir',
      'getFactureLibreToUpdate',
      'getFiliaeOfResponsable',
      'getAllTvas',
      'getAllUnites',
      'getRibOfFilials',
      'getTypeSociete',
      'TypeReglement',
      'ConditionPaiement'
    ]),
    computedCheckTypeFactureAvoir() {
      return (
        this.facture.type === "Facture d'avoir" &&
        this.facture.motif_avoir !== 'ristourne'
      );
    },
    computedCheckTypeFactureNotAvoir() {
      const isAvoirFacture = this.facture.type === "Facture d'avoir";
      const isAvoirFinancier = this.facture.motif_avoir === 'ristourne';

      return !isAvoirFacture || (isAvoirFacture && isAvoirFinancier);
    },
    computedCheckReglement() {
      return this.facture.type !== "Facture d'avoir";
    },
    computedTypeReglement() {
      var table = this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return [{ text: '', value: null }, ...table];
    },
    computedConditionPaiement() {
      var table = this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return table;
    },
    computedPermissionType() {
      let typeToDisplay = ['Facture', 'Facture proforma'];
      if (this.checkPermission('FLAVF') || this.checkPermission('FLCAPT')) {
        typeToDisplay.push("Facture d'avoir");
      }
      return typeToDisplay;
    },
    formateValueFacture() {
      return function(data) {
        return data
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          .replaceAll(',', ' ');
      };
    },
    totalQteForAvoir() {
      let sum = 0;
      if (this.computedCheckTypeFactureAvoir) {
        this.facture?.produits?.forEach(element => {
          if (element.qte_apres != null) {
            sum += parseFloat(element.qte_apres || 0);
          }
        });
      }
      return sum;
    },
    NetHt() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        this.facture?.produits?.forEach(element => {
          sum += parseFloat(element.total_ht || 0);
        });
      } else {
        this.facture?.produits?.forEach(element => {
          sum += parseFloat(element.total_ht_apres || 0);
        });
      }
      return sum;
    },
    totalHt() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        this.facture?.produits?.forEach(element => {
          sum +=
            parseFloat((element.qte + '').replace(',', '.') || 0) *
            (element.unite === '%'
              ? parseFloat((element.pu_ht + '').replace(',', '.') || 0) / 100
              : parseFloat((element.pu_ht + '').replace(',', '.') || 0));
        });
      } else {
        this.facture?.produits?.forEach(element => {
          if (element.qte_apres != null && element.pu_ht_apres != null)
            sum +=
              parseFloat((element.qte_apres + '').replace(',', '.') || 0) *
              (element.unite === '%'
                ? parseFloat(
                    (element.pu_ht_apres + '').replace(',', '.') || 0
                  ) / 100
                : parseFloat(
                    (element.pu_ht_apres + '').replace(',', '.') || 0
                  ));
        });
      }
      return sum;
    },
    total_tva() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        this.facture?.produits?.forEach(element => {
          sum +=
            Math.round(
              ((parseFloat((element.total_ht + '').replace(',', '.') || 0) *
                parseFloat((element.tva + '').replace(',', '.') || 0)) /
                100) *
                100
            ) / 100;
          // sum += parseFloat((element.total_ht * element.tva) / 100) || 0;
        });
      } else {
        this.facture?.produits?.forEach(element => {
          sum +=
            Math.round(
              ((parseFloat(
                (element.total_ht_apres + '').replace(',', '.') || 0
              ) *
                parseFloat((element.tva_apres + '').replace(',', '.') || 0)) /
                100) *
                100
            ) / 100;
        });
      }
      return sum;
    },
    totalTtc() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        this.facture?.produits?.forEach(element => {
          sum += parseFloat((element.total_ttc + '').replace(',', '.') || 0);
        });
      } else {
        this.facture?.produits?.forEach(element => {
          if (element.total_ttc_apres != null) {
            sum += parseFloat(
              (element.total_ttc_apres + '').replace(',', '.') || 0
            );
          }
        });
      }
      return sum;
    },
    computedSumNetHtBeforSt() {
      return function(index) {
        let sum = 0.0;
        for (let i = index - 1; i >= 0; i--) {
          if (this?.facture?.produits[i]?.type == 'produit') {
            sum += this.facture.produits[i].total_ht;
          } else {
            return Math.round(sum * 100) / 100;
          }
        }
        return Math.round(sum * 100) / 100;
      };
    },
    totalReduction() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        if (this.facture.comment_calculer_facture !== 'montant') {
          this.facture?.produits?.forEach(element => {
            sum +=
              (parseFloat((element.qte + '').replace(',', '.') || 0) *
                (element.unite === '%'
                  ? parseFloat((element.pu_ht + '').replace(',', '.') || 0) /
                    100
                  : parseFloat((element.pu_ht + '').replace(',', '.') || 0)) *
                parseFloat((element.reduction + '').replace(',', '.') || 0)) /
              100;
          });
        } else {
          this.facture?.produits?.forEach(element => {
            element.unite === '%'
              ? (sum +=
                  (parseFloat((element.reduction + '').replace(',', '.') || 0) /
                    100) *
                  parseFloat((element.qte + '').replace(',', '.') || 0))
              : (sum +=
                  parseFloat((element.reduction + '').replace(',', '.') || 0) *
                  parseFloat((element.qte + '').replace(',', '.') || 0));
          });
        }
      } else {
        if (this.facture.comment_calculer_facture !== 'montant') {
          this.facture?.produits?.forEach(element => {
            if (
              element.qte_apres != null &&
              element.pu_ht_apres != null &&
              element.reduction_apres != null
            ) {
              sum +=
                (parseFloat((element.qte_apres + '').replace(',', '.') || 0) *
                  (element.unite === '%'
                    ? parseFloat(
                        (element.pu_ht_apres + '').replace(',', '.') || 0
                      ) / 100
                    : parseFloat(
                        (element.pu_ht_apres + '').replace(',', '.') || 0
                      )) *
                  parseFloat(
                    (element.reduction_apres + '').replace(',', '.') || 0
                  )) /
                100;
            }
          });
        } else {
          this.facture?.produits?.forEach(element => {
            if (element.reduction_apres != null) {
              element.unite === '%'
                ? (sum +=
                    (parseFloat(
                      (element.reduction_apres + '').replace(',', '.') || 0
                    ) /
                      100) *
                    parseFloat((element.qte_apres + '').replace(',', '.') || 0))
                : (sum +=
                    parseFloat(
                      (element.reduction_apres + '').replace(',', '.') || 0
                    ) *
                    parseFloat(
                      (element.qte_apres + '').replace(',', '.') || 0
                    ));
            }
          });
        }
      }
      return sum;
    }
  },
  methods: {
    ...mapActions([
      'getFactureFilterForAvoir',
      'displayFactureTemplate',
      'getAllTemplateSociete',
      'getSettingFilialeTh',
      'addNewFactureLibreTh',
      'getAllCountreies',
      'fetchAllProducts',
      'getOneFacturesLibres',
      'displayFacture',
      'getFilialsOfConnectedResponsable',
      'addUniteTva',
      'getUnites',
      'getTvas',
      'getCategoriesFactureLibre',
      'addCategorie',
      'getAllErrorsCreationFactureLibre',
      'getAllRibOfFiliale',
      'fetchAllTypeSociete',
      'uploadFileLibre',
      'getAllTypesReglement',
      'getAllConditionsPaiement',
      'addNewClientPassage',
      'transformFormatObjetClientPassage'
    ]),
    changeText(event, index) {
      this.facture.produits[index].description = event;
    },
    changeLigneText(event, index) {
      this.facture.produits[index].contenu = event;
    },
    changeTextDescription(event) {
      this.facture.description = event;
    },
    changeTextObjet(event) {
      this.facture.objet = event;
    },
    resetModal() {
      this.configurationClientToAdd = {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: 'passage'
      };
      this.error = null;
    },
    handleSubmitClient() {
      this.loading = true;
      this.addNewClientPassage(this.configurationClientToAdd)
        .then(response => {
          if (response && response.error) {
            this.error = response.error;
            this.loading = false;
          }
          if (response.success) {
            this.error = null;
            this.transformFormatObjetClientPassage(response.response);
            this.passageClient = this.getSettingFilialesTh.filter(
              element => element.type === 'passage'
            );
            this.facture.acheteur.id = response.response.id;
            this.acheteurSelectedPro();
            this.hideModal('addClientModal');
            this.loading = false;
          }
        })
        .catch();
    },
    deleteUploadFile(index) {
      this.files.splice(index, 1);
    },
    async addCat() {
      if (this.nom_categorie === '' || this.nom_categorie === null) {
        this.errorCategorie = 'ce champ et obligatoire';
        return;
      } else {
        this.loadingCategorie = true;
        const response = await this.addCategorie({
          nom_categorie: this.nom_categorie
        });
        if (response.success) {
          this.$refs['addCategorieModal'].hide();
          this.categorieList.push(response.response);
          this.resteCategorieModal();
          this.loadingCategorie = false;
        } else {
          this.errorCategorie = response.error;
          this.loadingCategorie = false;
        }
      }
    },
    resteCategorieModal() {
      this.nom_categorie = null;
      this.errorCategorie = null;
    },
    onChangeTypeAvoir() {
      if (this.computedCheckTypeFactureAvoir) {
        const { motif_avoir } = this.facture;
        this.facture?.produits?.forEach(item => {
          if (motif_avoir === 'retour_avoir') {
            item.qte_apres = 0;
            item.pu_ht_apres = item.pu_ht_apres_avoir;
            item.reduction_apres = item.reduction;
          } else if (motif_avoir === 'avoir_financier') {
            item.qte_apres = item.qte_apres_avoir;
            item.pu_ht_apres = 0;
            item.reduction_apres = item.reduction;
          } else {
            item.qte_apres = item.qte;
            item.pu_ht_apres = item.pu_ht;
            item.reduction_apres = 0;
          }
        });
      }
    },
    resteTvaUniteModal() {
      this.valeur = null;
      this.errorTvaUnite = null;
    },
    async addTav(type) {
      if (this.valeur > 100) {
        this.errorTvaUnite = 'tva doit etre inferieur à 100';
      } else if (this.valeur === '' || this.valeur === null) {
        this.errorTvaUnite = 'ce champ et obligatoire';
      } else {
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: this.valeur,
          type: type
        });
        if (response.success) {
          this.$refs['addtvaModal'].hide();
          this.listTva.push(response.response.valeur);
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        } else {
          this.$refs['addtvaModal'].hide();
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        }
      }
    },
    async addUnite(type) {
      if (this.valeur === '' || this.valeur === null) {
        this.errorTvaUnite = 'ce champ et obligatoire';
      } else {
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: this.valeur,
          type: type
        });
        if (response.success) {
          this.$refs['addUnitModal'].hide();
          this.uniteList.push(response.response.valeur);
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        } else {
          this.$refs['addUnitModal'].hide();
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        }
      }
    },
    onChangeIbanVendeur() {
      this.facture.iban = this.facture.vendeur.iban;
    },
    onChangeSwiftVendeur() {
      this.facture.swift = this.facture.vendeur.swift;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    async filterResultsNumDoc() {
      this.isOpenDocumentList = true;
      const numeroDocumentCorrige = this.facture?.numero_document_corrige;
      if (numeroDocumentCorrige !== '') {
        let payload = {
          search: numeroDocumentCorrige
        };
        const response = await this.getFactureFilterForAvoir(payload);
        this.resultsListAvoir = response.success ? response.response : [];
      } else {
        this.OnchangeFactureType(this.facture.type)
        this.resultsListAvoir = [];
      }
    },
    OnchangeFactureType(typeFacture) {
      const DEFAULT_COLOR = '#000000';
      const DEFAULT_TVA = 0;
      const DEFAULT_QTE = 1;
      const DEFAULT_UNITE = null;
      const DEFAULT_PU_HT = 0;
      const DEFAULT_TOTAL_TTC = 0;
      const DEFAULT_TOTAL_HT = 0;
      this.disabledNumFactureAvoir = false;
      this.loading = false;
      this.showReduc = false;
      this.showDep = false;
      this.hideVendeur = false;
      this.hideAcheteur = false;
      this.error = null;
      this.isOpenAcheteurList = false;
      this.isOpenDocumentList = false;
      this.facture = {
        categorie: null,
        numero_document_corrige: null,
        numero_color: DEFAULT_COLOR,
        vendeur_color: DEFAULT_COLOR,
        acheteur_color: DEFAULT_COLOR,
        objet_color: DEFAULT_COLOR,
        ttc_color: DEFAULT_COLOR,
        barre_color: '#c3c7e2',
        text_color: DEFAULT_COLOR,
        motif_avoir: 'retour_avoir',
        devise: 'EUR',
        num: null,
        type: typeFacture,
        date_creation: moment(Date.now()).format('YYYY-MM-DD'),
        lieu_creation: null,
        categorie_id: null,
        objet: null,
        mode_reglement: null,
        date_limite_reglement: null,
        date_exact_limite_reglement: null,
        etat: typeFacture === 'Facture proforma' ? 'Créé' : 'A payer',
        montant_payer: 0,
        comment_calculer_facture: 'pourcentage',
        ajout_reduction_global: 0,
        swift: null,
        iban: null,
        check: false,
        date_paiement: null,
        vendeur: {
          nom_compagnie: null,
          code_postal: null,
          siren_tva: 'Numéro TVA',
          siren_value: null,
          tva_value: null,
          adresse: null,
          code_postal: null,
          ville: null,
          pays: null,
          iban: null,
          banque: null,
          swift: null,
          email: null,
          site_internet: null,
          fax: null,
          telephone: null,
          id_vendeur: null,
          description: null,
          type_societe: null,
          filiale_id: null
        },
        acheteur: {
          type: 'professionnel',
          civilite: 'M.',
          prenom: null,
          nom_famille: null,
          nom_compagnie: null,
          siren_tva: 'Numéro TVA',
          siren_value: null,
          tva_value: null,
          adresse: null,
          code_postal: null,
          ville: null,
          pays: null,
          email: null,
          telephone: null,
          telephone_portable: null,
          description: null,
          type_societe: null,
          filiale_id: null
        },
        produits: [
          {
            contenu: '',
            nom: null,
            reference: null,
            depot: null,
            reduction: 0,
            qte: DEFAULT_QTE,
            unite: DEFAULT_UNITE,
            tva: DEFAULT_TVA,
            pu_ht: DEFAULT_PU_HT,
            total_ttc: DEFAULT_TOTAL_TTC,
            total_ht: DEFAULT_TOTAL_HT,
            description: null,
            isSelectInputTva: false,
            isSelectInput: true,
            index: 0,
            type: 'produit',
            isOpenProduitList: false,
            contenu_apres: '',
            nom_apres: null,
            reference_apres: null,
            depot_apres: null,
            reduction_apres: 0,
            qte_apres: DEFAULT_QTE,
            unite_apres: DEFAULT_UNITE,
            tva_apres: DEFAULT_TVA,
            pu_ht_apres: DEFAULT_PU_HT,
            total_ttc_apres: DEFAULT_TOTAL_TTC,
            total_ht_apres: DEFAULT_TOTAL_HT,
            isSelectInputTva_apres: false,
            isSelectInput_apres: true,
            index_apres: 0,
            isOpenProduitList_apres: false,
            qte_sous_total_avoir: 0,
            pu_ht_sous_total_avoir: 0,
            total_ht_sous_total_avoir: 0,
            total_ttc_sous_total_avoir: 0,
            adresse: null
          }
        ],
        displayDiscription: true
      };
    },

    filterResultsProduit(produit, index, type) {
      this.focusInput = index;
      if (type == 'apres') {
        this.facture.produits[index].isOpenProduitList_apres = true;
      } else {
        this.facture.produits[index].isOpenProduitList = true;
      }
      this.resultsProduits = this.productListName.filter(item => {
        return item?.toLowerCase().startsWith(produit.toLowerCase());
      });
    },
    onChangeGlobalReduction(reductionGlobal) {
      const { produits, comment_calculer_facture } = this.facture;

      const calculateTVA = (total_ht, tva) => {
        const tvaAmount = Math.round(((total_ht * tva) / 100) * 100) / 100;
        return tvaAmount;
      };

      produits.forEach(item => {
        item.reduction = reductionGlobal;
        const qte = parseFloat((item.qte + '').replace(',', '.') || 0);
        const pu_ht = parseFloat((item.pu_ht + '').replace(',', '.') || 0);
        const unite =
          item.unite === '%'
            ? parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0)
            : pu_ht;

        if (comment_calculer_facture === 'pourcentage') {
          const total_ht =
            Math.round(
              (qte * unite - (qte * unite * reductionGlobal) / 100) * 100
            ) / 100;
          const tva = calculateTVA(
            total_ht,
            parseFloat((item.tva + '').replace(',', '.') || 0)
          );
          item.total_ht = total_ht;
          item.total_ttc_apres = Math.round((total_ht + tva) * 100) / 100;
        } else {
          const total_ht =
            Math.round(qte * (unite - reductionGlobal) * 100) / 100;
          const tva = calculateTVA(
            total_ht,
            parseFloat((item.tva + '').replace(',', '.') || 0)
          );
          item.total_ht = total_ht;
          item.total_ttc_apres = Math.round((total_ht + tva) * 100) / 100;
        }
      });
    },
    onChangeCommentCalculer() {
      const calculateTotalHT = item => {
        if (item.unite === '%') {
          return (
            parseFloat((item.qte + '').replace(',', '.') || 0) *
              parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0) -
            (parseFloat((item.qte + '').replace(',', '.') || 0) *
              parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0) *
              parseFloat((item.reduction + '').replace(',', '.') || 0)) /
              100
          );
        } else {
          return (
            parseFloat((item.qte + '').replace(',', '.') || 0) *
              parseFloat((item.pu_ht + '').replace(',', '.') || 0) -
            (parseFloat((item.qte + '').replace(',', '.') || 0) *
              parseFloat((item.pu_ht + '').replace(',', '.') || 0) *
              parseFloat((item.reduction + '').replace(',', '.') || 0)) /
              100
          );
        }
      };
      const calculateTotalTTC = (item, totalHT) => {
        let tva =
          (parseFloat((totalHT + '').replace(',', '.') || 0) *
            parseFloat((item.tva + '').replace(',', '.') || 0)) /
          100;

        return parseFloat((totalHT + '').replace(',', '.') || 0) + tva;
      };
      this.facture.produits.forEach(item => {
        let totalHT = 0;
        let totalTTC = 0;

        if (this.facture.comment_calculer_facture === 'pourcentage') {
          totalHT = Math.round(calculateTotalHT(item) * 100) / 100;
          totalTTC = Math.round(calculateTotalTTC(item, totalHT) * 100) / 100;
        } else {
          totalHT = Math.round(calculateTotalHT(item) * 100) / 100;
          totalTTC = Math.round(calculateTotalTTC(item, totalHT) * 100) / 100;
        }
        item.total_ht = totalHT;
        item.total_ttc_apres = totalTTC;
      });
    },
    onClickOutsideNumDoc() {
      this.isOpenDocumentList = false;
    },
    onClickOutsideProduct() {
      if (this.focusInput != null) {
        this.facture.produits[this.focusInput].isOpenProduitList = false;
        this.facture.produits[this.focusInput].isOpenProduitList_apres = false;
        this.focusInput = null;
      }
    },
    async setResultsListAvoir(num) {
      this.facture.numero_document_corrige = num;
      this.disabledNumFactureAvoir = true;
      this.showReduc = true;
      this.idAvoir = this.resultsListAvoir.find(
        element => element.num === num
      )?.id;
      const response = await this.getOneFacturesLibres(this.idAvoir);
      if (response.success) {
        let fact = this.getFactureLibreToUpdate;
        fact.categorie_id = this.getFactureLibreToUpdate?.categorie?.id;
        fact.type = "Facture d'avoir";
        fact.motif_avoir = 'retour_avoir';
        fact.isOpenProduitList = false;
        fact.isOpenProduitList_apres = false;
        fact.mode_reglement = parseInt(
          this.getFactureLibreToUpdate?.mode_reglement
        );
        fact.date_limite_reglement = parseInt(
          this.getFactureLibreToUpdate?.date_limite_reglement
        );
        fact.numero_document_corrige = this.facture.numero_document_corrige;
        fact.vendeur.id = this.getFactureLibreToUpdate?.vendeur?.filiale_id;
        fact.acheteur.id = this.getFactureLibreToUpdate?.acheteur?.filiale_id;
        fact.num = null;
        this.minDateForAvoir = this.getFactureLibreToUpdate?.date_creation;
        (fact.date_creation = moment(Date.now()).format('YYYY-MM-DD')),
          (this.facture = fact);
        this.facture.produits = this.facture.produits.map(item => {
          if (item.type == 'produit') {
            return {
              ...item,
              contenu_apres: '',
              nom_apres: item.nom,
              reference_apres: item.reference,
              depot_apres: null,
              reduction_apres:
                fact.motif_avoir === 'avoir_financier' ? 0 : item.reduction,
              qte_apres:
                fact.motif_avoir === 'retour_avoir' ||
                fact.motif_avoir === 'avoir_financier'
                  ? 0
                  : item.qte,
              unite_apres: item.unite,
              tva_apres: item.tva,
              pu_ht_apres:
                fact.motif_avoir === 'avoir_financier'
                  ? 0
                  : item.pu_ht_apres_avoir,
              total_ttc_apres: 0,
              total_ht_apres: 0,
              isSelectInputTva_apres: false,
              isSelectInput_apres: true,
              index_apres: 0,
              isOpenProduitList_apres: false
            };
          }
          return item;
        });
      }
    },
    setResultsProduct(result, produit) {
      produit.nom = result;
      produit.nom_apres = result;

      const currentProduit = this.getProduits.find(
        element => element.nom === result
      );
      if (currentProduit) {
        const puHt = parseFloat(currentProduit.pu_ht.replace(',', '.')) || 0;
        const tva = parseFloat(currentProduit.tva.replace(',', '.')) || 0;
        const qte = parseFloat((produit.qte + '').replace(',', '.')) || 0;

        produit.reference = currentProduit.reference;
        produit.pu_ht = puHt;
        produit.tva = tva;
        produit.total_ttc =
          Math.round((puHt + (puHt / 100) * tva) * qte * 100) / 100;
        produit.total_ht = Math.round(puHt * qte * 100) / 100;

        produit.reference_apres = currentProduit.reference;
        produit.pu_ht_apres = puHt;
        produit.tva_apres = tva;
        produit.total_ttc_apres =
          Math.round((puHt + (puHt / 100) * tva) * qte * 100) / 100;
        produit.total_ht_apres = Math.round(puHt * qte * 100) / 100;
      }
    },

    changeUnite(produit) {
      const qte = parseFloat((produit.qte + '').replace(',', '.')) || 0;
      const puHt = parseFloat((produit.pu_ht + '').replace(',', '.')) || 0;
      const reduction =
        parseFloat((produit.reduction + '').replace(',', '.')) || 0;
      const tva = parseFloat((produit.tva + '').replace(',', '.')) || 0;

      let totalHt;
      let tvaAmount;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        totalHt =
          Math.round(
            (qte * (produit.unite === '%' ? puHt / 100 : puHt) -
              (qte * (produit.unite === '%' ? puHt / 100 : puHt) * reduction) /
                100) *
              100
          ) / 100;
        tvaAmount = Math.round(((totalHt * tva) / 100) * 100) / 100;
      } else {
        totalHt =
          Math.round(
            qte * (produit.unite === '%' ? puHt - reduction : puHt) * 100
          ) / 100;
        tvaAmount = Math.round(((totalHt * tva) / 100) * 100) / 100;
      }

      produit.total_ht = totalHt;
      produit.total_ttc = Math.round((totalHt + tvaAmount) * 100) / 100;
    },
    calculTotalHtTtc(produit) {
      const qte = parseFloat((produit.qte + '').replace(',', '.')) || 0;
      const puHt = parseFloat((produit.pu_ht + '').replace(',', '.')) || 0;
      const reduction =
        parseFloat((produit.reduction + '').replace(',', '.')) || 0;
      const tva = parseFloat((produit.tva + '').replace(',', '.')) || 0;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        produit.total_ht =
          Math.round(
            (qte * (produit.unite === '%' ? puHt / 100 : puHt) -
              (qte * (produit.unite === '%' ? puHt / 100 : puHt) * reduction) /
                100) *
              100
          ) / 100;
        produit.total_ttc =
          Math.round(
            (produit.total_ht + (produit.total_ht * tva) / 100) * 100
          ) / 100;
      } else {
        produit.total_ht =
          Math.round(
            qte *
              (produit.unite === '%'
                ? (puHt - reduction) / 100
                : puHt - reduction) *
              100
          ) / 100;
        produit.total_ttc =
          Math.round(
            (produit.total_ht + (produit.total_ht * tva) / 100) * 100
          ) / 100;
      }
    },
    change_reduction(produit) {
      const qte = parseFloat((produit.qte + '').replace(',', '.')) || 0;
      const puHt = parseFloat((produit.pu_ht + '').replace(',', '.')) || 0;
      const reduction =
        parseFloat((produit.reduction + '').replace(',', '.')) || 0;
      const tva = parseFloat((produit.tva + '').replace(',', '.')) || 0;

      let totalHt;
      let tvaAmount;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        totalHt =
          Math.round(
            (qte * (produit.unite === '%' ? puHt / 100 : puHt) -
              (qte * (produit.unite === '%' ? puHt / 100 : puHt) * reduction) /
                100) *
              100
          ) / 100;
        tvaAmount = Math.round(((totalHt * tva) / 100) * 100) / 100;
      } else {
        totalHt =
          Math.round(
            qte * (produit.unite === '%' ? puHt - reduction : puHt) * 100
          ) / 100;
        tvaAmount = Math.round(((totalHt * tva) / 100) * 100) / 100;
      }

      produit.total_ht = totalHt;
      produit.total_ttc = Math.round((totalHt + tvaAmount) * 100) / 100;
    },
    change_reduction_apres(produit) {
      const qteApres =
        parseFloat((produit.qte_apres + '').replace(',', '.')) || 0;
      const puHtApres =
        parseFloat((produit.pu_ht_apres + '').replace(',', '.')) || 0;
      const reductionApres =
        parseFloat((produit.reduction_apres + '').replace(',', '.')) || 0;
      const unite = produit.unite;
      const tvaApres = produit.tva_apres;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        produit.total_ht_apres =
          Math.round(
            (qteApres * (unite === '%' ? puHtApres / 100 : puHtApres) -
              (qteApres *
                (unite === '%' ? puHtApres / 100 : puHtApres) *
                reductionApres) /
                100) *
              100
          ) / 100;

        const tva =
          Math.round(((produit.total_ht_apres * (tvaApres || 0)) / 100) * 100) /
          100;

        produit.total_ttc_apres =
          Math.round((produit.total_ht_apres + tva) * 100) / 100;
      } else {
        const netHtApresAvoir =
          parseFloat((produit.net_ht_apres_avoir + '').replace(',', '.')) || 0;
        const maxPuHt = puHtApres - reductionApres;

        produit.max_pu_ht = parseFloat(
          (netHtApresAvoir / qteApres + reductionApres).toFixed(2)
        );
        produit.total_ht_apres =
          Math.round(
            qteApres * (unite === '%' ? maxPuHt / 100 : maxPuHt) * 100
          ) / 100;

        const tva =
          Math.round(
            ((parseFloat((produit.total_ht + '').replace(',', '.')) *
              (parseFloat((produit.tva + '').replace(',', '.')) || 0)) /
              100) *
              100
          ) / 100;

        produit.total_ttc_apres =
          Math.round((produit.total_ht_apres + tva) * 100) / 100;
      }
    },

    calculTotalHtTtc_apres(produit) {
      const qteApres =
        parseFloat((produit.qte_apres + '').replace(',', '.')) || 0;
      const puHtApres =
        parseFloat((produit.pu_ht_apres + '').replace(',', '.')) || 0;
      const reductionApres =
        parseFloat((produit.reduction_apres + '').replace(',', '.')) || 0;
      const tvaApres =
        parseFloat((produit.tva_apres + '').replace(',', '.')) || 0;

      let totalHtApres;
      let tvaAmountApres;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        totalHtApres =
          Math.round(
            (qteApres * (produit.unite === '%' ? puHtApres / 100 : puHtApres) -
              (qteApres *
                (produit.unite === '%' ? puHtApres / 100 : puHtApres) *
                reductionApres) /
                100) *
              100
          ) / 100;
        tvaAmountApres =
          Math.round(((totalHtApres * tvaApres) / 100) * 100) / 100;
      } else {
        produit.max_pu_ht =
          parseFloat(produit.net_ht_apres_avoir / qteApres) + reductionApres;
        totalHtApres =
          Math.round(
            qteApres *
              (produit.unite === '%' ? puHtApres - reductionApres : puHtApres) *
              100
          ) / 100;
        tvaAmountApres =
          Math.round(((totalHtApres * tvaApres) / 100) * 100) / 100;
      }

      produit.total_ht_apres = totalHtApres;
      produit.total_ttc_apres =
        Math.round((totalHtApres + tvaAmountApres) * 100) / 100;
    },
    acheteurSelectedParticulier() {
      const item = this.particulierSocite.find(
        element => element.id === this.facture.acheteur.id
      );

      if (item) {
        const {
          id,
          prenom,
          nom_famille,
          civilite,
          siren_tva,
          siren_value,
          tva_value,
          adresse,
          code_postal,
          ville,
          pays,
          telephone,
          email,
          telephone_portable,
          description,
          type_societe,
          siret
        } = item;

        this.facture.acheteur.filiale_id = id;
        this.facture.acheteur.nom_compagnie = prenom + ' ' + nom_famille;
        this.facture.acheteur.civilite = civilite;
        this.facture.acheteur.nom_famille = nom_famille;
        this.facture.acheteur.prenom = prenom;
        this.facture.acheteur.siren_tva = siren_tva;
        this.facture.acheteur.siren_value = siren_value;
        this.facture.acheteur.tva_value = tva_value;
        this.facture.acheteur.adresse = adresse;
        this.facture.acheteur.code_postal = code_postal;
        this.facture.acheteur.ville = ville;
        this.facture.acheteur.pays = pays;
        this.facture.acheteur.telephone = telephone;
        this.facture.acheteur.code_postal = code_postal;
        this.facture.date_limite_reglement = item.payment_condition?.id;
        this.facture.acheteur.pays = pays;
        this.facture.acheteur.email = email;
        this.facture.acheteur.telephone = telephone;
        this.facture.acheteur.telephone_portable = telephone_portable;
        this.facture.acheteur.description = description;
        this.facture.acheteur.type_societe = type_societe;
        this.facture.acheteur.siret = siret;
        this.facture.acheteur.siren_tva = 'Numéro TVA';
      }
    },
    acheteurSelectedPro() {
      const table =
        this.facture.acheteur.type === 'passage'
          ? this.passageClient
          : this.professionnelSocite;
      const item = table.find(
        element => element.id === this.facture.acheteur.id
      );

      if (item) {
        this.facture.acheteur.filiale_id = item.id;
        this.facture.acheteur.nom_compagnie = item.name;
        this.facture.acheteur.civilite = item.civilite;
        this.facture.acheteur.nom_famille = item.nom_famille;
        this.facture.acheteur.siren_value = item.siren_value;
        this.facture.acheteur.tva_value = item.tva_value;
        this.facture.acheteur.adresse = item.adresse;
        this.facture.acheteur.code_postal = item.code_postal;
        this.facture.acheteur.ville = item.ville;
        this.facture.acheteur.pays = item.pays;
        this.facture.acheteur.telephone = item.telephone;
        this.facture.acheteur.code_postal = item.code_postal;
        this.facture.acheteur.pays = item.pays;
        this.facture.acheteur.email = item.email;
        this.facture.acheteur.telephone = item.telephone;
        this.facture.date_limite_reglement = item.payment_condition;
        this.facture.acheteur.telephone_portable = item.telephone_portable;
        this.facture.acheteur.description = item.description;
        this.facture.acheteur.type_societe = item.type_societe;
        this.facture.acheteur.siret = item.siret;
        this.facture.acheteur.siren_tva = 'Numéro TVA';
      }
    },
    async vendeurSelected() {
      const itemId = this.facture.vendeur.id;
      const item = this.getFiliaeOfResponsable.find(
        element => element.id === itemId
      );
      if (!item) return;

      this.id_societe = item.id;
      this.facture.vendeur.filiale_id = item.id;
      this.facture.vendeur.nom_compagnie = item.name;
      this.facture.vendeur.email = item.email;
      this.facture.vendeur.adresse = item.adresse;
      this.facture.vendeur.iban = item.iban;
      this.facture.vendeur.swift = item.swift;
      this.facture.vendeur.site_internet = item.site_internet;
      this.facture.vendeur.fax = item.fax;
      this.facture.vendeur.telephone = item.telephone;
      this.facture.vendeur.code_postal = item.code_postal;
      this.facture.vendeur.ville = item.ville;
      this.facture.vendeur.pays = item.pays;
      this.facture.mode_reglement = item.payment_type?.id;
      this.facture.vendeur.description = item.description;
      this.facture.vendeur.type_societe =
        item.type === 'particulier' ? null : item.type_societe;
      this.facture.vendeur.siret = item.siret;
      this.facture.vendeur.siren_value = item.siren_value;
      this.facture.vendeur.tva_value = item.tva_value;
      this.loderTemplatesList = true;
      const response2 = await this.getAllTemplateSociete('libre');
      if (response2 === true) {
        const result = this.templateSociete?.filter(
          item => item.hasOwnProperty('favori') && item.favori === true
        );
        this.facture.template_id = result.length ? result[0].id : null;
      }
      this.loderTemplatesList = false;
      await this.getAllRibOfFiliale(item.id);
      this.rib = null;
      this.facture.iban = null;
      this.facture.swift = null;
      this.facture.bank = null;

      const ribWithLibreFamille = this.getRibOfFilials.find(rib =>
        rib.familles.some(item => item.famille === 'libre')
      );
      if (ribWithLibreFamille) {
        this.rib = ribWithLibreFamille;
        this.facture.iban = ribWithLibreFamille.iban;
        this.facture.swift = ribWithLibreFamille.swift;
        this.facture.bank = ribWithLibreFamille.bank;
      }
    },
    toggleUnSelectMarket(e) {
      this.facture.iban = null;
      this.facture.swift = null;
      this.facture.bank = null;
    },
    onChangeRibSelect(e) {
      this.facture.iban = e?.iban;
      this.facture.swift = e?.swift;
      this.facture.bank = e?.bank;
    },
    showMoreVendeur() {
      this.hideVendeur = !this.hideVendeur;
    },
    showMoreAcheteur() {
      this.hideAcheteur = !this.hideAcheteur;
    },
    showReduction() {
      this.showReduc = true;
    },
    showDepot() {
      this.showDep = true;
    },
    hideReduction() {
      this.showReduc = false;
    },
    hideDepot() {
      this.showDep = false;
    },
    addNewProduct() {
      this.facture.produits.push({
        contenu: '',
        nom: null,
        reference: null,
        depot: null,
        reduction: 0,
        qte: 1,
        unite: null,
        tva: 20,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        description: null,
        isSelectInputTva: false,
        isSelectInput: true,
        index: 0,
        type: 'produit',
        isOpenProduitList: false,
        contenu_apres: '',
        nom_apres: null,
        reference_apres: null,
        depot_apres: null,
        reduction_apres: 0,
        qte_apres: 1,
        unite_apres: ' ',
        tva_apres: 0,
        pu_ht_apres: 0,
        total_ttc_apres: 0,
        total_ht_apres: 0,
        isSelectInputTva_apres: false,
        isSelectInput_apres: true,
        index_apres: 0,
        isOpenProduitList_apres: false,
        qte_sous_total_avoir: 0,
        pu_ht_sous_total_avoir: 0,
        total_ht_sous_total_avoir: 0,
        total_ttc_sous_total_avoir: 0,
        adresse: null
      });
    },
    addNewSousToltal() {
      this.facture.produits.push({
        qte: 0,
        unite: null,
        reduction: 0,
        tva: 0,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        index: 0,
        type: 'sous_total',
        contenu: '',
        index: 0
      });
    },
    addNewSautPage() {
      this.facture.produits.push({
        qte: 0,
        reduction: 0,
        unite: null,
        tva: 0,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        index: 0,
        type: 'saut_page',
        contenu: '',
        index: 0,
        contenu: ''
      });
    },
    addNewTextLine() {
      this.facture.produits.push({
        qte: 0,
        unite: null,
        reduction: 0,
        tva: 0,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        index: 0,
        type: 'ligne_text',
        contenu: ''
      });
    },
    deleteProduct(index, type, produit) {
      if (type === 'ligne_text') {
        const indice = this.facture.produits.indexOf(produit);
        if (indice !== -1) {
          this.facture.produits.splice(indice, 1);
        }
      } else {
        this.facture.produits.splice(index, 1);
      }
    },
    async submitFactureLibre() {
      this.disabledSaveButton = true;
      if (
        this.totalTtc < this.facture.montant_payer &&
        this.computedCheckTypeFactureNotAvoir
      ) {
        this.errorValidation = 'Acompte Payé ne doit pas dépasser le total TTC';
      } else {
        if (this.computedCheckTypeFactureAvoir) {
          const response = await this.getOneFacturesLibres(this.idAvoir);
          if (response.success) {
            let fact = this.getFactureLibreToUpdate;
            this.facture.produits.map((item, index) => {
              if (item.type == 'produit') {
                item.qte_apres_avoir = fact.produits[index].qte_apres_avoir;
                item.net_ht_apres_avoir =
                  fact.produits[index].net_ht_apres_avoir;
              }
            });
          }
        }
        if (
          this?.facture?.vendeur?.id !== undefined &&
          this?.facture?.vendeur?.id !== null &&
          this.facture.date_creation !== null
        ) {
          const res = await this.getAllErrorsCreationFactureLibre({
            type: this.facture.type,
            id: this?.facture?.vendeur?.id,
            date_creation: this.facture.date_creation
          });
          if (res.success) {
            this.testRetourErrors = true;
            this.disabledSaveButton = false;
          } else {
            this.testRetourErrors = false;
            this.disabledSaveButton = false;
            this.$swal.fire({
              background: 'rgb(245 245 252)',
              type: 'error',
              icon: 'error',
              allowOutsideClick: false,
              showCloseButton: true,
              showConfirmButton: false,
              html:
                '<h5 id="text-error"><b>' +
                res.error +
                '</b></h5>' +
                " <p><centre> Veuillez contacter l'administrateur pour apporter les corrections nécessaires</centre></p>" +
                '<br>' +
                '' +
                '<div style="display:flex;justify-content:space-around">' +
                '<button  type="button" role="button" id="SwalBtn" tabindex="0" class="SwalBtn1 customSwalBtn" style=" border: 0;outline: 0;padding: 10px;border-radius: 9px;color: white;background: #f8be40;">' +
                'Essayer de nouveau' +
                '</button>' +
                '</div>',
              willOpen: () => {
                document
                  .getElementById('SwalBtn')
                  .addEventListener('click', async () => {
                    document.getElementById('SwalBtn').innerHTML =
                      'Essayer de nouveau' + '<div>Chargement ...</div>';
                    const response2 = await this.getAllErrorsCreationFactureLibre(
                      {
                        type: this?.facture?.type,
                        id: this?.facture?.vendeur?.id,
                        date_creation: this?.facture?.date_creation
                      }
                    );
                    if (response2.success) {
                      this.$swal.close();
                      this.testRetourErrors = true;
                      this.disabledSaveButton = false;
                    } else {
                      this.testRetourErrors = false;
                      if (this.checkPermission('GS')) {
                        var url = '/setting/gestion-filiale-th';
                        var win = window.open(url, '_blank');
                        win.myData = {
                          nom: this.facture.vendeur.nom_compagnie
                        };
                        win.focus();
                      }
                      document.getElementById('text-error').innerHTML =
                        response2.error;
                      document.getElementById('SwalBtn').innerHTML =
                        'Essayer de nouveau';
                      this.disabledSaveButton = false;
                    }
                  });
              }
            });
          }
        }
        if (this.testRetourErrors) {
          if (
            this.facture.type === "Facture d'avoir" &&
            this.facture.motif_avoir === 'retour_avoir' &&
            this.totalQteForAvoir === 0
          ) {
            this.$fire('Aucun changement détecté');
          } else {
            this.facture.famille = 'libre';
            this.facture.displayDiscription === true
              ? (this.facture.displayDiscription = 1)
              : (this.facture.displayDiscription = 0);
            if (this.totalHt <= 0 && this.totalReduction == 0.0) {
              this.$swal.fire({
                title: 'Erreur',
                text: 'On ne peut pas générer une facture avec total HT 0.',
                icon: 'error',
                confirButtonText: 'OK'
              });
            } else {
              this.loading = true;
              // FILTER PRODUIT NOT AVOIR
              if (this.facture.type === "Facture d'avoir" && this.facture.motif_avoir != 'ristourne') {
                if (
                  this.facture &&
                  this.facture.produits &&
                  this.facture.produits.length
                ) {
                  this.facture.produits = this.facture.produits.filter(
                    item =>
                      item.total_ttc_apres != 0 &&
                      item.total_ht_aprestotal_ht_apres != 0
                  );
                }
              }
              const response = await this.addNewFactureLibreTh(this.facture);
              if (response.success === true) {
                {
                  if (this.files.length != 0) {
                    let bodyFormData = new FormData();
                    bodyFormData.append('facture_id', response.response);
                    for (let i = 0; i < this.files?.length; i++) {
                      bodyFormData.append(
                        'files[' + i + '][file]',
                        this.files[i]
                      );
                      if (this.files[i]['description'] != undefined) {
                        bodyFormData.append(
                          'files_descriptions[' + i + ']',
                          this.files[i]['description']
                        );
                      }
                    }
                    await this.uploadFileLibreroute({
                      bodyFormData: bodyFormData,
                      facture_id: response.response,
                      vue: false
                    });

                    this.$router.push(`/facture/${this.addFactureLibre.id}`);
                    this.loading = false;
                    this.disabledSaveButton = false;
                  } else {
                    this.$router.push(`/facture/${this.addFactureLibre.id}`);
                    this.loading = false;
                    this.disabledSaveButton = false;
                  }
                }
              } else {
                this.loading = false;
                this.disabledSaveButton = false;
              }
            }
          }
        }
      }
    },
    async displayBill() {
      this.loading = true;
      this.facture.famille = 'libre';
      if (this.facture.template_id == null) {
        const response = await this.displayFacture(this.facture);
        if (response.success === true) {
          this.loading = false;
          this.pdfSource = response.response;
        } else {
          this.error = response.error;
          this.loading = false;
        }
      } else {
        let template = {
          id_template: this.facture.template_id,
          id_facture: null,
          facture: this.facture,
          action: 'apercu'
        };
        const response = await this.displayFactureTemplate(template);
        if (response.success === true) {
          this.loading = false;
          this.pdfSource = response.response;
        } else {
          this.error = response.error;
          this.loading = false;
        }
      }
    },
    resetAcheteur() {
      this.facture.acheteur.civilite = 'M.';
      this.facture.acheteur_color = '#000000';
      this.facture.acheteur.nom_compagnie = null;
      this.facture.acheteur.name = null;
      this.facture.acheteur.siren_tva = 'Numéro TVA';
      this.facture.acheteur.siren_value = null;
      this.facture.acheteur.tva_value = null;
      this.facture.acheteur.type_societe = null;
      this.facture.acheteur.adresse = null;
      this.facture.acheteur.code_postal = null;
      this.facture.acheteur.ville = null;
      this.facture.acheteur.pays = null;
      this.facture.acheteur.email = null;
      this.facture.acheteur.telephone = null;
      this.facture.acheteur.telephone_portable = null;
      this.facture.acheteur.description = null;
      this.facture.acheteur.siret = null;
      this.facture.acheteur.prenom = null;
      this.facture.acheteur.nom_famille = null;
      this.facture.acheteur.iban = null;
      this.facture.acheteur.swift = null;
    }
  },

  async mounted() {
    await this.getAllConditionsPaiement();
    await this.getAllTypesReglement();
    this.loadingClient = true;
    await this.getSettingFilialeTh();
    this.loadingClient = false;
    this.loadingSociete = true;
    await this.getFilialsOfConnectedResponsable();
    this.loadingSociete = false;
    this.professionnelSocite = this.getSettingFilialesTh.filter(
      element => element.type === 'professionnel' || element.type === 'organisme' || element.type === 'master filiale'
    );
    this.particulierSocite = this.getSettingFilialesTh.filter(
      element => element.type === 'particulier'
    );
    this.passageClient = this.getSettingFilialesTh.filter(
      element => element.type === 'passage'
    );
    this.professionnelSocite?.map(item =>
      this.filialsListNames?.push(item.name)
    );
    this.particulierSocite?.map(item =>
      this.filialsListParticuluerNames?.push(
        item.prenom + ' ' + item.nom_famille
      )
    );
    await this.getUnites();
    await this.getTvas();
    await this.getCategoriesFactureLibre();
    this.listTva = this.getAllTvas;
    this.uniteList = this.getAllUnites;
    this.categorieList = this.getAllCategories;
    await this.getAllCountreies();
    await this.fetchAllProducts();
    await this.fetchAllTypeSociete();
    this.getListFactureForAvoir?.map(item =>
      this.avoirListNums?.push(item.num)
    );
    let countryNames = [];
    this.getPaysList?.map(element => countryNames.push(element.nom));
    this.uniqueArrayOfCountries = [...new Set(countryNames)];
    this.getProduits?.map(item => this.productListName?.push(item.nom));
  }
};
</script>
<style lang="scss" scoped>
* {
  font-family: 'Montserrat', sans-serif;
}
.body-box-setting {
  display: flex;
  background-color: #f5f4fa;
  height: auto;
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}
.body-box-rapport {
  height: calc(100vh - 68px);
  .tabs-body-style {
    width: 220px;
    .tab-item-style {
      display: inline;
      .title-tab-item {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 500;
        color: #5d5d5d;
      }
      .ligne {
        width: 100%;
        margin: auto;
        margin-top: 7px;
        margin-bottom: 7px;
      }
    }
  }
  .display-tabs {
    display: none;
  }
  .table-rapport-style {
    .table {
      height: calc(100% - 50px);
    }
    .b-table-sticky-header {
      max-height: calc(100vh - 175px) !important;
      height: calc(100vh - 175px);
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
  .table-rapport-style-societe {
    width: 48%;
  }
  .table-rapport-style-client {
    width: 48%;
    margin-left: 4%;
  }
  @media screen and (max-width: 690px) {
    .table-rapport-style-societe,
    .table-rapport-style-client {
      width: 100%;
      margin-left: 0%;
      margin-bottom: 5px;
    }
  }
  .table-rapport-style-type {
    width: 100%;
  }
  .width-table-rapport {
    width: 100%;
  }
}
#filiale-box-body::-webkit-scrollbar,
#filiale-body::-webkit-scrollbar {
  width: 7px;
}
#filiale-box-body::-webkit-scrollbar-track,
#filiale-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
#filiale-box-body::-webkit-scrollbar-thumb,
#filiale-body::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 7px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select-vue-component-style.dep .multiselect__tags,
.dep .multiselect__tag {
  font-size: 13px;
}
.select-vue-component-style.dep .multiselect__option--highlight {
  background: #9799d64d;
  border-radius: 0;
  color: #333;
}
.select-vue-component-style.dep .multiselect__option--selected {
  border-radius: 0;
  background: #258ecf;
  color: #fff;
}
.select-vue-component-style.dep .multiselect__option {
  min-height: 20px;
  line-height: 9px;
  font-size: 13px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.contenu-facture-libre {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
}
.color_picker_width {
  width: 25px;
}
.padding-input-number {
  padding: 5px;
}
.font-tabel-produit {
  font-size: 14px;
}
.btn-add-tva-unite {
  height: 17px;
  width: 17px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}
.icon-plus-tva-unite {
  color: #fff;
  height: 11px;
  width: 11px;
  margin-bottom: 3px;
}
.margin-hesder-table {
  margin-right: 30px;
}
.td-width {
  width: 150px;
}
.button-danger-style-ligne-text {
  width: 34px;
  height: 35px;
  border-radius: 20px;
}
</style>
<style lang="scss">
.form-type-file {
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 56%;
  margin: auto;

  .form-groupe {
    .file-header-titles {
      align-items: center;
      padding: 4px 20px;
      display: flex;

      .file-name-title,
      .file-category-title {
        text-align: center;
        color: #495057;
        font-weight: 600;
        border-radius: 6px;
        // background-color: #8d8cb7;
        padding: 3px;
      }

      .file-name-title {
        width: 60%;
        margin-right: 10px;
      }

      .file-category-title {
        width: 40%;
      }
    }

    .files-to-upload {
      display: flex;
      align-items: center;
      padding: 2px 20px;

      .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-upload-icon {
          color: #2dabe2;
        }
        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }
      .file-name-trash {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 20%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-upload-icon {
          color: #2dabe2;
        }
        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }
      .file-category {
        width: 40%;
        font-size: 13px;
        border-radius: 17px;
      }
    }

    .actionFile {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;

      .button-cancel-style {
        background-color: #49505782;
        color: white;
        border: none;
      }

      .chargement {
        margin-left: 5px;

        .spinner-border {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .error {
      color: red;
      font-size: 14px;
      text-align: center;
    }
  }
}

.d-block {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.actionModel {
  text-align: center;

  .button-cancel-style {
    width: 100px;
  }
}
.more_less {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #428bca;
  font-size: 13px;
  float: right;
  width: 100px;
  margin: 0;
}
.more_less:hover {
  text-decoration: underline;
}
.scroll-bar {
  overflow-y: scroll;
  padding: 10px;
  overflow-x: hidden;
}
.aligne {
  flex-direction: row;
  display: flex;
}
.background-style {
  background-color: #fff;
  border-radius: 25px;
}
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
.list_satyle_num_avoir {
  position: absolute;
  z-index: 50;
  background-color: #fff;
  width: 425px;
  height: auto;
  max-height: 300px;
}
.list_style_products {
  position: absolute;
  z-index: 50;
  background-color: #fff;
  width: 250px;
  height: auto;
  max-height: 125px;
}
.list_satyle {
  position: absolute;
  z-index: 50;
  background-color: #fff;
  width: 250px;
  height: auto;
  max-height: 200px;
}
.list_satyle_prenom {
  position: absolute;
  z-index: 50;
  background-color: #fff;
  width: 170px;
  height: auto;
  max-height: 200px;
}
.button-13 {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: 'Amazon Ember', sans-serif;
  font-size: 13px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 100px;
}

.button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  outline: 0;
}
.text-line {
  line-height: 0.5;
}
.button-produit {
  background-color: #adb5bd;
}
</style>
<style lang="scss">
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Parcourir...';
  background-color: #4d4bac;
  color: white;
  height: 37px;
}

.custom-file-input:lang(en) ~ .custom-file-label {
  border: none;
  border-radius: 20px;
  background-color: #fff;

  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    pointer-events: none;
    margin-top: 5px;
  }
}
#upload-file-component {
  overflow-y: auto;
  max-height: 321px;
  position: relative;
}
.doc-list-file {
  width: 100%;
  .files-tabs {
    .nav-tabs {
      justify-content: center;
      background-color: #fff;
      padding: 5px 0;
      border: none;
      border-radius: 49px;
      width: 57%;
      margin: auto;
      margin-bottom: 15px;

      .files-tab-title {
        position: relative;
        font-size: 12px;
        color: #404346;
        background-color: transparent;
        border: 1px solid #d5d5d5;
        border-radius: 20px;
        padding: 0px 10px;
        padding: 2px 11px;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;
        border: none;
        margin-right: 8px;

        .counter {
          color: #858585;
          margin-left: 5px;
        }

        &.active {
          color: white !important;
          border-radius: 20px;
          border: none;
          background-color: #9799d6 !important;
          padding: 2px 11px;

          .counter {
            color: #2dabe2;
          }
        }
      }
    }
  }
}
</style>
